/**
 * @fileoverview Listagem de Funcionários do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável pela listagem, cadastro, edição e 
 * remoção de funcionários.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { 
  listarFuncionarios, 
  deletarFuncionario, 
  criarFuncionario, 
  atualizarFuncionario 
} from '../servicos/funcionariosServico';
import { FaEdit, FaTrash, FaPlus, FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify';
import FormularioFuncionario from './formularios/FormularioFuncionario';

/**
 * @typedef {Object} Funcionario
 * @property {number} id - ID do funcionário
 * @property {string} nome - Nome completo
 * @property {string} cpf - CPF formatado
 * @property {string} email - Email
 * @property {string} telefone - Telefone formatado
 * @property {string} data_nascimento - Data de nascimento
 * @property {string} cargo - Cargo do funcionário
 * @property {Array<string>} especialidades - Lista de especialidades
 * @property {string} status - Status (ativo/inativo)
 */

/**
 * @typedef {Object} Paginacao
 * @property {number} pagina - Página atual
 * @property {number} limite - Itens por página
 * @property {number} total - Total de registros
 */

/**
 * Mapeamento de cargos para exibição
 * @constant {Object.<string, string>}
 */
const CARGOS = {
  barbeiro: 'Barbeiro',
  barbeiro_master: 'Barbeiro Master',
  barbeiro_senior: 'Barbeiro Sênior',
  aprendiz: 'Aprendiz',
  gerente: 'Gerente'
};

/**
 * Mapeamento de especialidades para exibição
 * @constant {Object.<string, string>}
 */
const ESPECIALIDADES = {
  corte_cabelo: 'Corte Cabelo',
  corte_navalhado: 'Corte Navalhado',
  sobrancelha: 'Sobrancelha',
  platinado: 'Platinado',
  luzes: 'Luzes'
};

/**
 * Componente de diálogo de confirmação
 * @param {Object} props - Propriedades do componente
 */
function DialogoConfirmacao({ estaAberto, aoConfirmar, aoCancelar, mensagem }) {
  if (!estaAberto) return null;

  return (
    <div 
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="titulo-dialogo"
    >
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm">
        <h3 
          id="titulo-dialogo" 
          className="text-lg font-bold mb-4"
        >
          Confirmar Exclusão
        </h3>
        <p className="mb-6">{mensagem}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={aoCancelar}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded 
                     hover:bg-gray-400 transition-colors duration-200"
          >
            Cancelar
          </button>
          <button
            onClick={aoConfirmar}
            className="px-4 py-2 bg-red-500 text-white rounded 
                     hover:bg-red-600 transition-colors duration-200"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * Componente principal de listagem de funcionários
 * @returns {React.Component} Componente ListaFuncionarios
 */
function ListaFuncionarios() {
  // Estados
  const [funcionarios, setFuncionarios] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);
  const [funcionarioParaDeletar, setFuncionarioParaDeletar] = useState(null);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [funcionarioParaEditar, setFuncionarioParaEditar] = useState(null);

  // Estados de paginação e busca
  const [paginacao, setPaginacao] = useState({
    pagina: 1,
    limite: 10,
    total: 0
  });
  const [termoBusca, setTermoBusca] = useState('');

  /**
   * Carrega a lista de funcionários com paginação e filtros
   */
  const carregarFuncionarios = useCallback(async () => {
    try {
      setCarregando(true);
      console.log('📥 Carregando funcionários...', {
        pagina: paginacao.pagina,
        limite: paginacao.limite,
        busca: termoBusca
      });

      const data = await listarFuncionarios(
        paginacao.pagina, 
        paginacao.limite, 
        termoBusca
      );

      console.log(`✅ ${data.funcionarios.length} funcionários carregados`);
      setFuncionarios(data.funcionarios);
      setPaginacao(prev => ({ ...prev, total: data.total }));
    } catch (erro) {
      console.error('❌ Erro ao carregar funcionários:', erro);
      setErro('Falha ao carregar funcionários. Tente novamente.');
      toast.error('Não foi possível carregar os funcionários.');
    } finally {
      setCarregando(false);
    }
  }, [paginacao.pagina, paginacao.limite, termoBusca]);

  // Carrega funcionários quando os filtros mudam
  useEffect(() => {
    carregarFuncionarios();
  }, [carregarFuncionarios]);

  /**
   * Inicia cadastro de novo funcionário
   */
  const handleNovoFuncionario = useCallback(() => {
    console.log('📝 Iniciando cadastro de novo funcionário');
    setFuncionarioParaEditar(null);
    setMostrarFormulario(true);
  }, []);

  /**
   * Inicia edição de funcionário
   * @param {Funcionario} funcionario - Funcionário a ser editado
   */
  const handleEditar = useCallback((funcionario) => {
    console.log('📝 Iniciando edição:', funcionario);
    setFuncionarioParaEditar(funcionario);
    setMostrarFormulario(true);
  }, []);

  /**
   * Salva funcionário (novo ou editado)
   * @param {Funcionario} dados - Dados do funcionário
   */
  const handleSalvar = useCallback(async (dados) => {
    try {
      console.log('💾 Salvando funcionário:', dados);

      if (funcionarioParaEditar) {
        await atualizarFuncionario(funcionarioParaEditar.id, dados);
        toast.success('Funcionário atualizado com sucesso!');
      } else {
        await criarFuncionario(dados);
        toast.success('Funcionário cadastrado com sucesso!');
      }

      setMostrarFormulario(false);
      await carregarFuncionarios();
    } catch (erro) {
      console.error('❌ Erro ao salvar funcionário:', erro);
      
      if (erro.response?.status === 409) {
        toast.error('CPF ou email já cadastrado');
      } else {
        toast.error('Erro ao salvar funcionário. Tente novamente.');
      }
    }
  }, [funcionarioParaEditar, carregarFuncionarios]);

  /**
   * Inicia processo de deleção
   * @param {number} id - ID do funcionário
   */
  const handleDeletar = useCallback((id) => {
    console.log('🗑️ Iniciando deleção:', id);
    setFuncionarioParaDeletar(id);
  }, []);

  /**
   * Confirma deleção do funcionário
   */
  const confirmarDeletar = useCallback(async () => {
    try {
      console.log('⚠️ Confirmando deleção:', funcionarioParaDeletar);
      await deletarFuncionario(funcionarioParaDeletar);
      
      toast.success('Funcionário removido com sucesso');
      await carregarFuncionarios();
    } catch (erro) {
      console.error('❌ Erro ao deletar funcionário:', erro);
      toast.error('Erro ao remover funcionário. Tente novamente.');
    } finally {
      setFuncionarioParaDeletar(null);
    }
  }, [funcionarioParaDeletar, carregarFuncionarios]);

  /**
   * Formata data para exibição
   * @param {string} data - Data a ser formatada
   * @returns {string} Data formatada
   */
  const formatarData = useCallback((data) => {
    if (!data) return '';
    try {
      const [ano, mes, dia] = data.split('-');
      return `${dia}/${mes}/${ano}`;
    } catch (erro) {
      console.error('❌ Erro ao formatar data:', erro);
      return '';
    }
  }, []);

  /**
   * Formata cargo para exibição
   * @param {string} cargo - Cargo a ser formatado
   * @returns {string} Cargo formatado
   */
  const formatarCargo = useCallback((cargo) => {
    return CARGOS[cargo] || cargo;
  }, []);

  /**
   * Formata especialidades para exibição
   * @param {string|Array} especialidades - Especialidades a serem formatadas
   * @returns {string} Especialidades formatadas
   */
  const formatarEspecialidades = useCallback((especialidades) => {
    try {
      const listaEspecialidades = typeof especialidades === 'string' 
        ? JSON.parse(especialidades) 
        : especialidades;

      return listaEspecialidades
        .map(esp => ESPECIALIDADES[esp] || esp)
        .join(' • ');
    } catch (erro) {
      console.error('❌ Erro ao formatar especialidades:', erro);
      return especialidades;
    }
  }, []);

  // Early returns para estados de carregamento e erro
  if (carregando) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        <span className="ml-3 text-gray-600">Carregando funcionários...</span>
      </div>
    );
  }

  if (erro) {
    return (
      <div className="text-center py-10">
        <p className="text-red-600 text-lg mb-4">{erro}</p>
        <button
          onClick={carregarFuncionarios}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 
                   transition-colors duration-200"
        >
          Tentar Novamente
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      {/* Cabeçalho */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          Lista de Funcionários
        </h1>
        <button 
          onClick={handleNovoFuncionario}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 
                   flex items-center transition-colors duration-200"
          aria-label="Adicionar novo funcionário"
        >
          <FaPlus className="mr-2" aria-hidden="true" /> 
          Novo Funcionário
        </button>
      </div>

      {/* Barra de Busca */}
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Buscar funcionários..."
          value={termoBusca}
          onChange={(e) => setTermoBusca(e.target.value)}
          className="w-full p-3 pl-10 border rounded-lg 
                   focus:outline-none focus:ring-2 focus:ring-blue-500
                   transition-all duration-200"
          aria-label="Buscar funcionários"
        />
        <FaSearch 
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
          aria-hidden="true"
        />
      </div>

      {/* Modal de Formulário */}
      {mostrarFormulario && (
        <div className="fixed inset-0 z-[1000] overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center px-4">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
            <div className="relative z-[1001] w-[600px] bg-white rounded-lg shadow-xl">
              <FormularioFuncionario
                funcionarioParaEditar={funcionarioParaEditar}
                onSalvar={handleSalvar}
                onCancelar={() => setMostrarFormulario(false)}
              />
            </div>
          </div>
        </div>
      )}

      {/* Diálogo de Confirmação */}
      <DialogoConfirmacao
        estaAberto={funcionarioParaDeletar !== null}
        aoConfirmar={confirmarDeletar}
        aoCancelar={() => setFuncionarioParaDeletar(null)}
        mensagem="Tem certeza que deseja deletar este funcionário? Esta ação não pode ser desfeita."
      />

      {/* Tabela de Funcionários */}
      <div className="overflow-x-auto shadow rounded-lg">
        {funcionarios.length === 0 ? (
          <div className="bg-white p-8 text-center text-gray-600">
            <p>Nenhum funcionário encontrado.</p>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nome
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  CPF
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Telefone
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data Nascimento
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Cargo
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Especialidades
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {funcionarios.map((funcionario) => (
                <tr key={funcionario.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {funcionario.nome}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {funcionario.cpf}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {funcionario.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {funcionario.telefone}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatarData(funcionario.data_nascimento)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatarCargo(funcionario.cargo)}
                  </td>
                  <td className="px-6 py-4">
                    {formatarEspecialidades(funcionario.especialidades)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`
                      px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                      ${funcionario.status === 'ativo' 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'}
                    `}>
                      {funcionario.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button
                      onClick={() => handleEditar(funcionario)}
                      className="text-blue-600 hover:text-blue-900 mr-4 
                               transition-colors duration-200"
                      title="Editar funcionário"
                      aria-label={`Editar ${funcionario.nome}`}
                    >
                      <FaEdit size={18} />
                    </button>
                    <button
                      onClick={() => handleDeletar(funcionario.id)}
                      className="text-red-600 hover:text-red-900
                               transition-colors duration-200"
                      title="Deletar funcionário"
                      aria-label={`Deletar ${funcionario.nome}`}
                    >
                      <FaTrash size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Paginação */}
      <div className="mt-6 flex justify-between items-center">
        <span className="text-sm text-gray-700">
          Mostrando {((paginacao.pagina - 1) * paginacao.limite) + 1} a{' '}
          {Math.min(paginacao.pagina * paginacao.limite, paginacao.total)} de{' '}
          {paginacao.total} funcionários
        </span>
        <div className="flex space-x-2">
          <button
            onClick={() => setPaginacao(p => ({ ...p, pagina: p.pagina - 1 }))}
            disabled={paginacao.pagina === 1}
            className={`
              px-4 py-2 border rounded text-sm font-medium
              ${paginacao.pagina === 1 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50'}
              transition-colors duration-200
            `}
          >
            Anterior
          </button>
          <span className="px-4 py-2 text-gray-600">
            Página {paginacao.pagina} de {Math.ceil(paginacao.total / paginacao.limite)}
          </span>
          <button
            onClick={() => setPaginacao(p => ({ ...p, pagina: p.pagina + 1 }))}
            disabled={paginacao.pagina >= Math.ceil(paginacao.total / paginacao.limite)}
            className={`
              px-4 py-2 border rounded text-sm font-medium
              ${paginacao.pagina >= Math.ceil(paginacao.total / paginacao.limite)
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50'}
              transition-colors duration-200
            `}
          >
            Próxima
          </button>
        </div>
      </div>
    </div>
  );
}

export default ListaFuncionarios;