/**
 * @fileoverview Serviço de funcionários do BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 */

import api, { tratarErroAPI } from './api';

/**
 * Lista funcionários com paginação
 * @async
 * @param {Object} params - Parâmetros de listagem
 * @returns {Promise<Object>} Dados dos funcionários
 */
export const listarFuncionarios = async ({ pagina = 1, limite = 10, busca = '' }) => {
  try {
    console.log('📋 Listando funcionários:', { pagina, limite, busca });
    
    const resposta = await api.get('/funcionarios', {
      params: { pagina, limite, busca }
    });
    
    console.log(`✅ ${resposta.data.funcionarios?.length || 0} funcionários encontrados`);
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'listar funcionários');
  }
};

/**
 * Busca funcionário por ID
 * @async
 * @param {number} id - ID do funcionário
 * @returns {Promise<Object>} Dados do funcionário
 */
export const buscarFuncionario = async (id) => {
  try {
    console.log('🔍 Buscando funcionário:', id);
    const resposta = await api.get(`/funcionarios/${id}`);
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'buscar funcionário');
  }
};

/**
 * Cria novo funcionário
 * @async
 * @param {Object} dadosFuncionario - Dados do funcionário
 * @returns {Promise<Object>} Funcionário criado
 */
export const criarFuncionario = async (dadosFuncionario) => {
  try {
    console.log('📝 Criando funcionário:', dadosFuncionario);
    const resposta = await api.post('/funcionarios', dadosFuncionario);
    console.log('✅ Funcionário criado com sucesso');
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'criar funcionário');
  }
};

/**
 * Atualiza dados do funcionário
 * @async
 * @param {number} id - ID do funcionário
 * @param {Object} dadosFuncionario - Novos dados
 * @returns {Promise<Object>} Funcionário atualizado
 */
export const atualizarFuncionario = async (id, dadosFuncionario) => {
  try {
    console.log(`📝 Atualizando funcionário ${id}:`, dadosFuncionario);
    const resposta = await api.put(`/funcionarios/${id}`, dadosFuncionario);
    console.log('✅ Funcionário atualizado com sucesso');
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'atualizar funcionário');
  }
};

/**
 * Remove um funcionário
 * @async
 * @param {number} id - ID do funcionário
 * @returns {Promise<Object>} Resultado da operação
 */
export const deletarFuncionario = async (id) => {
  try {
    console.log('🗑️ Removendo funcionário:', id);
    const resposta = await api.delete(`/funcionarios/${id}`);
    console.log('✅ Funcionário removido com sucesso');
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'remover funcionário');
  }
};