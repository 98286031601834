import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { definirToken } from '../servicos/autenticacao';
import api from '../servicos/api';
import logoLogin from '../assets/images/logo_bbs2.png';

function Login({ definirAutenticado }) {
  const [credenciais, setCredenciais] = useState({ nomeUsuario: '', senha: '' });
  const [carregando, setCarregando] = useState(false);
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const navegar = useNavigate();

  const realizarLogin = async (evento) => {
    evento.preventDefault();
    if (!credenciais.nomeUsuario.trim() || !credenciais.senha.trim()) {
      toast.warning('Preencha seus dados para entrar');
      return;
    }

    setCarregando(true);
    try {
      const resposta = await api.post('/autenticacao/login', credenciais);
      definirToken(resposta.data.token);
      definirAutenticado(true);
      toast.success('Bem-vindo ao sistema!');
      navegar('/dashboard');
    } catch (erro) {
      toast.error('Ops! Dados incorretos. Tente novamente.');
    } finally {
      setCarregando(false);
    }
  };

  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      {/* Efeito de luz diagonal */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-neutral-900 to-black" />
      <div className="absolute inset-0 opacity-30"
           style={{
             backgroundImage: 'linear-gradient(45deg, #B8860B 0%, transparent 10%, transparent 90%, #B8860B 100%)'
           }} />

      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="w-full max-w-6xl grid lg:grid-cols-2 gap-12 items-center">
          {/* Seção da Logo */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="lg:order-2"
          >
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-[#B8860B] via-[#FFD700] to-[#B8860B] opacity-20 blur-2xl rounded-full" />
              <div className="relative bg-black/40 backdrop-blur-sm p-12 rounded-2xl border border-[#B8860B]/30">
                <img 
                  src={logoLogin} 
                  alt="BarbaBranca System" 
                  className="w-full max-w-md mx-auto filter drop-shadow-2xl"
                />
                <div className="mt-8 text-center">
                  <h2 className="text-2xl font-bold bg-gradient-to-r from-[#B8860B] via-[#FFD700] to-[#B8860B] text-transparent bg-clip-text">
                    Excelência em Gestão de Barbearias
                  </h2>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Formulário */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="lg:order-1"
          >
            <div className="max-w-md mx-auto">
              <form onSubmit={realizarLogin} className="space-y-8">
                <div className="space-y-2">
                  <label className="text-sm uppercase tracking-wider text-[#B8860B]">
                    Nome de usuário
                  </label>
                  <input
                    type="text"
                    name="nomeUsuario"
                    value={credenciais.nomeUsuario}
                    onChange={(e) => setCredenciais({ ...credenciais, nomeUsuario: e.target.value })}
                    className="w-full px-6 py-4 bg-black/50 rounded-lg text-white
                             border border-[#B8860B]/30 focus:border-[#B8860B]
                             focus:ring-2 focus:ring-[#B8860B]/20
                             placeholder-gray-500
                             transition-all duration-300"
                    placeholder="Digite seu usuário"
                    disabled={carregando}
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm uppercase tracking-wider text-[#B8860B]">
                    Senha
                  </label>
                  <div className="relative">
                    <input
                      type={mostrarSenha ? "text" : "password"}
                      name="senha"
                      value={credenciais.senha}
                      onChange={(e) => setCredenciais({ ...credenciais, senha: e.target.value })}
                      className="w-full px-6 py-4 bg-black/50 rounded-lg text-white
                               border border-[#B8860B]/30 focus:border-[#B8860B]
                               focus:ring-2 focus:ring-[#B8860B]/20
                               placeholder-gray-500
                               transition-all duration-300"
                      placeholder="Digite sua senha"
                      disabled={carregando}
                    />
                    <button
                      type="button"
                      onClick={() => setMostrarSenha(!mostrarSenha)}
                      className="absolute right-4 top-1/2 -translate-y-1/2 text-[#B8860B]/70
                               hover:text-[#B8860B] transition-colors"
                    >
                      {mostrarSenha ? 
                        <EyeSlashIcon className="h-5 w-5" /> : 
                        <EyeIcon className="h-5 w-5" />
                      }
                    </button>
                  </div>
                </div>

                <motion.button
                  type="submit"
                  disabled={carregando}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full px-6 py-4 rounded-lg text-lg font-medium
                           bg-gradient-to-r from-[#B8860B] via-[#FFD700] to-[#B8860B]
                           text-black
                           hover:from-[#FFD700] hover:via-[#B8860B] hover:to-[#FFD700]
                           focus:outline-none focus:ring-2 focus:ring-[#B8860B]
                           disabled:opacity-50 disabled:cursor-not-allowed
                           transition-all duration-300
                           shadow-lg shadow-[#B8860B]/20"
                >
                  {carregando ? (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                      className="w-6 h-6 border-3 border-black border-t-transparent 
                                rounded-full mx-auto"
                    />
                  ) : (
                    'Acessar'
                  )}
                </motion.button>

                {/* Detalhe decorativo */}
                <div className="pt-4 flex items-center gap-4 opacity-50">
                  <div className="flex-1 h-px bg-gradient-to-r from-transparent via-[#B8860B] to-transparent" />
                  <div className="text-[#B8860B] text-xs uppercase tracking-widest">
                    Sistema Profissional
                  </div>
                  <div className="flex-1 h-px bg-gradient-to-r from-transparent via-[#B8860B] to-transparent" />
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Login;