/**
 * @fileoverview Detalhes do Agendamento do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Modal de detalhes do agendamento, permitindo visualização,
 * edição, conclusão e exclusão de agendamentos.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { XCircleIcon, CheckIcon } from '@heroicons/react/24/outline';

/**
 * @typedef {Object} Agendamento
 * @property {number} id - ID do agendamento
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço a ser realizado
 * @property {number} valor - Valor do serviço
 * @property {string} data - Data e hora do agendamento
 * @property {string} status - Status do agendamento
 */

/**
 * @typedef {Object} PropsDetalhes
 * @property {Agendamento} agendamento - Dados do agendamento
 * @property {Function} aoEditar - Callback de edição
 * @property {Function} aoDeletar - Callback de deleção
 * @property {Function} aoConcluir - Callback de conclusão
 * @property {Function} aoFechar - Callback para fechar modal
 */

/**
 * Configurações de animação do modal
 * @constant {Object}
 */
const ANIMACAO_MODAL = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 50 },
  transition: { duration: 0.2 }
};

/**
 * Mapeamento de cores por status
 * @constant {Object.<string, string>}
 */
const CORES_STATUS = {
  concluido: 'bg-green-100 text-green-800',
  pendente: 'bg-yellow-100 text-yellow-800',
  default: 'bg-gray-100 text-gray-800'
};

/**
 * Formata valor monetário
 * @param {number} valor - Valor a ser formatado
 * @returns {string} Valor formatado em BRL
 */
const formatarValor = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valor || 0);
};

/**
 * Formata data e hora
 * @param {string} data - Data a ser formatada
 * @returns {string} Data formatada
 */
const formatarData = (data) => {
  try {
    if (!data) return 'Data não disponível';
    return format(
      parseISO(data), 
      "d 'de' MMMM 'às' HH:mm", 
      { locale: ptBR }
    );
  } catch (erro) {
    console.error('❌ Erro ao formatar data:', erro);
    return 'Data inválida';
  }
};

/**
 * Obtém classe CSS para cor do status
 * @param {string} status - Status do agendamento
 * @returns {string} Classes CSS
 */
const obterCorStatus = (status) => {
  return CORES_STATUS[status] || CORES_STATUS.default;
};

/**
 * Componente de detalhes do agendamento
 * @param {PropsDetalhes} props - Propriedades do componente
 * @returns {React.Component} Modal de detalhes
 */
function DetalhesAgendamento({ 
  agendamento, 
  aoEditar, 
  aoDeletar, 
  aoConcluir, 
  aoFechar 
}) {
  const [carregando, setCarregando] = useState(false);
  const [erro, setErro] = useState(null);

  /**
   * Processa conclusão do agendamento
   * @param {React.MouseEvent} evento - Evento do clique
   */
  const handleConcluir = async (evento) => {
    evento.preventDefault();
    if (!aoConcluir || carregando) return;

    try {
      setCarregando(true);
      setErro(null);
      console.log('✨ Concluindo agendamento:', agendamento.id);
      await aoConcluir(agendamento.id);
      aoFechar();
    } catch (erro) {
      console.error('❌ Erro ao concluir agendamento:', erro);
      setErro('Não foi possível concluir o agendamento. Tente novamente.');
    } finally {
      setCarregando(false);
    }
  };

  /**
   * Processa deleção do agendamento
   */
  const handleDeletar = async () => {
    if (!aoDeletar || carregando) return;

    const confirmacao = window.confirm(
      'Tem certeza que deseja excluir este agendamento? Esta ação não pode ser desfeita.'
    );

    if (!confirmacao) return;

    try {
      setCarregando(true);
      setErro(null);
      console.log('🗑️ Deletando agendamento:', agendamento.id);
      await aoDeletar(agendamento.id);
      aoFechar();
    } catch (erro) {
      console.error('❌ Erro ao deletar agendamento:', erro);
      setErro('Não foi possível excluir o agendamento. Tente novamente.');
    } finally {
      setCarregando(false);
    }
  };

  return (
    <motion.div
      {...ANIMACAO_MODAL}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-titulo"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        {/* Cabeçalho */}
        <div className="flex justify-between items-center mb-4">
          <h3 
            id="modal-titulo"
            className="text-lg font-medium text-gray-900"
          >
            Detalhes do Agendamento
          </h3>
          <button
            onClick={aoFechar}
            disabled={carregando}
            className="text-gray-400 hover:text-gray-500 transition-colors"
            aria-label="Fechar detalhes"
          >
            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        {/* Mensagem de Erro */}
        {erro && (
          <div 
            className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded"
            role="alert"
          >
            {erro}
          </div>
        )}
        
        {/* Informações */}
        <div className="space-y-3">
          <p className="flex justify-between">
            <strong className="text-gray-700">Cliente:</strong>
            <span className="text-gray-900">
              {agendamento.nome_cliente || 'Não especificado'}
            </span>
          </p>
          
          <p className="flex justify-between">
            <strong className="text-gray-700">Serviço:</strong>
            <span className="text-gray-900">{agendamento.servico}</span>
          </p>
          
          <p className="flex justify-between">
            <strong className="text-gray-700">Valor:</strong>
            <span className="text-gray-900 font-medium">
              {formatarValor(agendamento.valor)}
            </span>
          </p>
          
          <p className="flex justify-between">
            <strong className="text-gray-700">Data e Hora:</strong>
            <span className="text-gray-900">
              {formatarData(agendamento.data)}
            </span>
          </p>

          <p className="flex justify-between items-center">
            <strong className="text-gray-700">Status:</strong>
            <span className={`
              px-2 py-1 rounded-full text-sm
              ${obterCorStatus(agendamento.status)}
            `}>
              {agendamento.status || 'pendente'}
            </span>
          </p>
        </div>

        {/* Botões */}
        <div className="mt-6 flex justify-between space-x-2">
          {agendamento.status !== 'concluido' && (
            <>
              <button
                onClick={() => !carregando && aoEditar(agendamento)}
                disabled={carregando}
                className={`
                  bg-blue-500 hover:bg-blue-700 text-white font-bold 
                  py-2 px-4 rounded transition-colors duration-200
                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                  ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
                `}
                aria-label="Editar agendamento"
              >
                Editar
              </button>
              
              <button
                onClick={handleConcluir}
                disabled={carregando}
                className={`
                  bg-green-500 hover:bg-green-700 text-white font-bold 
                  py-2 px-4 rounded transition-colors duration-200
                  focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2
                  ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
                `}
                aria-label="Concluir agendamento"
              >
                {carregando ? 'Concluindo...' : 'Concluir'}
              </button>
              
              <button
                onClick={handleDeletar}
                disabled={carregando}
                className={`
                  bg-red-500 hover:bg-red-700 text-white font-bold 
                  py-2 px-4 rounded transition-colors duration-200
                  focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
                  ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
                `}
                aria-label="Excluir agendamento"
              >
                {carregando ? 'Excluindo...' : 'Excluir'}
              </button>
            </>
          )}
          
          <button
            onClick={aoFechar}
            disabled={carregando}
            className={`
              bg-gray-500 hover:bg-gray-700 text-white font-bold 
              py-2 px-4 rounded transition-colors duration-200
              focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2
              ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
            `}
            aria-label="Fechar detalhes"
          >
            Fechar
          </button>
        </div>
      </div>
    </motion.div>
  );
}

// Verificação de tipos em desenvolvimento
if (process.env.NODE_ENV === 'development') {
  DetalhesAgendamento.propTypes = {
    agendamento: PropTypes.shape({
      id: PropTypes.number.isRequired,
      nome_cliente: PropTypes.string,
      servico: PropTypes.string.isRequired,
      valor: PropTypes.number,
      data: PropTypes.string.isRequired,
      status: PropTypes.string
    }).isRequired,
    aoEditar: PropTypes.func.isRequired,
    aoDeletar: PropTypes.func.isRequired,
    aoConcluir: PropTypes.func.isRequired,
    aoFechar: PropTypes.func.isRequired
  };
}

export default DetalhesAgendamento;