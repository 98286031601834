/**
 * @fileoverview Componente principal da aplicação BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Gerencia o roteamento e o layout principal da aplicação,
 * incluindo autenticação e proteção de rotas.
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Importação de páginas
import Login from './paginas/Login';
import Dashboard from './paginas/Dashboard';
import Agendamentos from './paginas/Agendamentos';
import Historico from './paginas/Historico';

// Importação de componentes
import BarraLateral from './componentes/layout/BarraLateral';
import ListaClientes from './componentes/ListaClientes';
import ListaFuncionarios from './componentes/ListaFuncionarios';

// Importação de serviços
import { estaAutenticado } from './servicos/autenticacao';

/**
 * Layout principal da aplicação com barra lateral
 * @param {Object} props - Propriedades do componente
 * @param {React.ReactNode} props.children - Conteúdo a ser renderizado
 */
const Layout = ({ children }) => (
  <div className="flex h-screen bg-gray-100">
    <BarraLateral />
    <div className="flex-1 overflow-auto p-6">
      {children}
    </div>
  </div>
);

/**
 * Componente principal da aplicação
 * @returns {React.Component} Componente App
 */
function App() {
  // Estado de autenticação
  const [usuarioAutenticado, setUsuarioAutenticado] = useState(false);

  // Verifica autenticação ao carregar
  useEffect(() => {
    const autenticado = estaAutenticado();
    console.log('📝 Estado de autenticação:', autenticado);
    setUsuarioAutenticado(autenticado);
  }, []);

  // Rota protegida que requer autenticação
  const RotaProtegida = ({ children }) => {
    return usuarioAutenticado ? (
      <Layout>{children}</Layout>
    ) : (
      <Navigate to="/login" replace />
    );
  };

  return (
    <Router>
      <ToastContainer />
      <Routes>
        {/* Rota pública */}
        <Route 
          path="/login" 
          element={
            <Login definirAutenticado={setUsuarioAutenticado} />
          } 
        />

        {/* Rotas protegidas */}
        <Route 
          path="/dashboard"
          element={
            <RotaProtegida>
              <Dashboard />
            </RotaProtegida>
          }
        />

        <Route 
          path="/agendamentos" 
          element={
            <RotaProtegida>
              <Agendamentos />
            </RotaProtegida>
          }
        />

        <Route 
          path="/clientes" 
          element={
            <RotaProtegida>
              <ListaClientes />
            </RotaProtegida>
          }
        />

        <Route 
          path="/funcionarios" 
          element={
            <RotaProtegida>
              <ListaFuncionarios />
            </RotaProtegida>
          }
        />

        <Route 
          path="/historico" 
          element={
            <RotaProtegida>
              <Historico />
            </RotaProtegida>
          }
        />

        {/* Rota padrão */}
        <Route 
          path="/" 
          element={
            <Navigate 
              to={usuarioAutenticado ? "/dashboard" : "/login"} 
              replace 
            />
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;