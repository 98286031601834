/**
 * @fileoverview Dashboard principal do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Página principal do sistema que exibe métricas e 
 * agendamentos do dia em tempo real.
 */

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Calendar, Clock, Users } from 'lucide-react';
import api from '../servicos/api';
import { useAtualizacaoAPI } from '../hooks/useAtualizacaoAPI';
import { formatarData } from '../utils/formatadorData';
import { calcularPorcentagem } from '../utils/formatadorTexto';

/**
 * @typedef {Object} Metricas
 * @property {number} agendamentosHoje - Total de agendamentos do dia
 * @property {number} clientesAtivos - Total de clientes ativos
 * @property {number} taxaOcupacao - Taxa de ocupação do dia
 */

/**
 * @typedef {Object} Agendamento
 * @property {number} id - ID do agendamento
 * @property {Object} cliente - Dados do cliente
 * @property {string} servico - Serviço agendado
 * @property {string} horario - Horário do agendamento
 */

/**
 * Configurações do dashboard
 * @constant {Object}
 */
const CONFIG = {
  CAPACIDADE_DIARIA: 30,
  INTERVALO_ATUALIZACAO: 300000 // 5 minutos
};

/**
 * Cards de estatísticas
 * @type {Array<{title: string, Icon: Component, bgColor: string, iconColor: string}>}
 */
const CARDS_ESTATISTICAS = [
  { 
    title: "Agendamentos Hoje",
    Icon: Calendar,
    bgColor: "bg-blue-50",
    iconColor: "text-blue-500"
  },
  { 
    title: "Clientes Ativos",
    Icon: Users,
    bgColor: "bg-green-50",
    iconColor: "text-green-500"
  },
  { 
    title: "Taxa de Ocupação",
    Icon: Clock,
    bgColor: "bg-orange-50",
    iconColor: "text-orange-500"
  }
];

/**
 * Componente de loading
 */
const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-screen" role="status">
    <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-200 border-t-blue-500" />
    <p className="mt-4 text-gray-600">Carregando dados...</p>
  </div>
);

/**
 * Componente de erro
 * @param {Object} props - Propriedades do componente
 */
const ErrorMessage = ({ onRetry }) => (
  <div className="flex flex-col items-center justify-center h-screen" role="alert">
    <div className="text-red-500 mb-4">Erro ao carregar dados do dashboard</div>
    <button 
      onClick={onRetry}
      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 
                 transition-colors focus:outline-none focus:ring-2 
                 focus:ring-blue-500 focus:ring-offset-2"
    >
      Tentar novamente
    </button>
  </div>
);

/**
 * Componente Dashboard
 * @returns {React.Component} Página do Dashboard
 */
function Dashboard() {
  // Estados
  const [metricas, setMetricas] = useState({
    agendamentosHoje: 0,
    clientesAtivos: 0,
    taxaOcupacao: 0
  });
  const [agendamentosProximos, setAgendamentosProximos] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);

  // Monitora atualizações
  useAtualizacaoAPI('/api/agendamentos', () => {
    console.log('🔄 Atualizando dados após mudança em agendamentos');
    carregarDados();
  });
  
  useAtualizacaoAPI('/api/clientes/ativos', () => {
    console.log('🔄 Atualizando dados após mudança em clientes');
    carregarDados();
  });

  /**
   * Processa agendamentos do dia
   */
  const processarAgendamentos = useCallback((agendamentos) => {
    if (!Array.isArray(agendamentos)) {
      console.error('❌ Dados de agendamentos inválidos:', agendamentos);
      return [];
    }

    const hoje = new Date();
    const inicioHoje = new Date(
      hoje.getFullYear(),
      hoje.getMonth(),
      hoje.getDate()
    ).toLocaleDateString();

    console.log('📅 Processando agendamentos para:', inicioHoje);

    const agendamentosFiltrados = agendamentos
      .filter(ag => {
        if (!ag?.data) return false;

        const dataAgendamento = new Date(ag.data);
        const dataAgendamentoLocal = new Date(
          dataAgendamento.getFullYear(),
          dataAgendamento.getMonth(),
          dataAgendamento.getDate()
        ).toLocaleDateString();

        return dataAgendamentoLocal === inicioHoje;
      })
      .sort((a, b) => new Date(a.data) - new Date(b.data));

    console.log(`✅ ${agendamentosFiltrados.length} agendamentos processados`);

    return agendamentosFiltrados.map(ag => ({
      id: ag.id,
      cliente: {
        nome: ag.nome_cliente || ag.cliente?.nome || 'Cliente não identificado'
      },
      servico: ag.servico || 'Serviço não especificado',
      horario: ag.data
    }));
  }, []);

/**
  * Carrega dados do dashboard
  */
  const carregarDados = useCallback(async () => {
    try {
     setCarregando(true);
     setErro(null);
    
     const hoje = new Date();
     const dataHoje = hoje.toLocaleDateString('en-CA');
     console.log('📊 Buscando dados para:', dataHoje);
    
      const [agendamentosRes, clientesRes] = await Promise.all([
        api.get('/agendamentos', { params: { data: dataHoje } }),
       api.get('/clientes/ativos')
     ]);

      // Extrai os agendamentos e clientes da resposta
     const agendamentos = agendamentosRes.data?.agendamentos || [];
     const { total: totalClientes } = clientesRes.data || { total: 0 };

     console.log('✨ Dados processados:', {
       agendamentosHoje: agendamentos.length,
       clientesAtivos: totalClientes,
        taxaOcupacao: calcularPorcentagem(agendamentos.length, CONFIG.CAPACIDADE_DIARIA)
      });

     setMetricas({
       agendamentosHoje: agendamentos.length,
       clientesAtivos: totalClientes,
        taxaOcupacao: calcularPorcentagem(agendamentos.length, CONFIG.CAPACIDADE_DIARIA)
     });

     // Processa os agendamentos para exibição
      const agendamentosProcessados = agendamentos.map(ag => ({
        id: ag.id,
       cliente: {
         nome: ag.nome_cliente || ag.cliente?.nome || 'Cliente não identificado'
        },
       servico: ag.servico || 'Serviço não especificado',
       horario: ag.data
      }));

     setAgendamentosProximos(agendamentosProcessados);
   } catch (erro) {
      console.error('❌ Erro ao carregar dados:', erro);
      setErro('Não foi possível carregar os dados do dashboard');
   } finally {
     setCarregando(false);
    }
  }, []);

  // Carrega dados iniciais e configura atualização periódica
  useEffect(() => {
    carregarDados();
    const intervalo = setInterval(carregarDados, CONFIG.INTERVALO_ATUALIZACAO);
    return () => clearInterval(intervalo);
  }, [carregarDados]);

  // Memoriza cards de estatísticas
  const cards = useMemo(() => 
    CARDS_ESTATISTICAS.map((card, index) => ({
      ...card,
      value: index === 0 ? metricas.agendamentosHoje :
             index === 1 ? metricas.clientesAtivos :
             `${metricas.taxaOcupacao}%`
    }))
  , [metricas]);

  if (carregando) return <LoadingSpinner />;
  if (erro) return <ErrorMessage onRetry={carregarDados} />;

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Cabeçalho */}
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Dashboard BarbaBranca</h1>
        <p className="text-gray-500">Visão geral do seu negócio</p>
        <button 
          onClick={carregarDados}
          className="mt-2 text-sm text-blue-500 hover:text-blue-600 
                     transition-colors focus:outline-none focus:ring-2
                     focus:ring-blue-500 focus:ring-offset-2"
          aria-label="Atualizar dados do dashboard"
        >
          Atualizar dados
        </button>
      </header>

      {/* Cards de Estatísticas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {cards.map((card, index) => (
          <div 
            key={index}
            className="bg-white rounded-lg shadow p-6 hover:shadow-lg 
                       transition-all duration-200 transform hover:-translate-y-1"
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-sm text-gray-500">{card.title}</h2>
                <p className="text-2xl font-bold mt-2">{card.value}</p>
              </div>
              <div className={`${card.bgColor} p-3 rounded-lg`} aria-hidden="true">
                <card.Icon className={`h-6 w-6 ${card.iconColor}`} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Lista de Agendamentos */}
      <section className="grid grid-cols-1 gap-6" aria-label="Agendamentos do dia">
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <header className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">Agendamentos de Hoje</h2>
              <span className="text-sm text-gray-500">
                {agendamentosProximos.length} agendamentos
              </span>
            </header>

            {agendamentosProximos.length > 0 ? (
              <div className="space-y-4">
                {agendamentosProximos.map((agendamento) => (
                  <div 
                    key={agendamento.id}
                    className="flex items-center justify-between p-3 bg-gray-50 
                             rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 bg-blue-100 rounded-full 
                                    flex items-center justify-center"
                           aria-hidden="true">
                        <Users className="h-5 w-5 text-blue-500" />
                      </div>
                      <div>
                        <p className="font-medium line-clamp-1">
                          {agendamento.cliente.nome}
                        </p>
                        <p className="text-sm text-gray-500 line-clamp-1">
                          {agendamento.servico}
                        </p>
                      </div>
                    </div>
                    <time className="text-sm text-gray-500 whitespace-nowrap">
                      {formatarData(agendamento.horario, 'HH:mm')}
                    </time>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8 text-gray-500">
                <Calendar className="h-12 w-12 mx-auto mb-4 text-gray-400" 
                         aria-hidden="true" />
                <p>Não há agendamentos para hoje</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;