/**
 * @fileoverview Serviço de Autenticação do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Gerencia autenticação, tokens JWT e interceptadores de requisição.
 */

import axios from 'axios';
import api from './api';

/**
 * @typedef {Object} TokenPayload
 * @property {number} exp - Timestamp de expiração
 * @property {number} iat - Timestamp de emissão
 * @property {string} id - ID do usuário
 * @property {string} email - Email do usuário
 */

/**
 * Chave usada para armazenar o token no localStorage
 * @constant {string}
 */
const CHAVE_TOKEN = 'token';

/**
 * Obtém o token JWT armazenado
 * @returns {string|null} Token JWT ou null se não existir
 */
export const obterToken = () => localStorage.getItem(CHAVE_TOKEN);

/**
 * Define o token JWT no localStorage
 * @param {string} token - Token JWT a ser armazenado
 */
export const definirToken = (token) => {
  if (!token) {
    console.warn('⚠️ Tentativa de definir um token inválido');
    return;
  }
  localStorage.setItem(CHAVE_TOKEN, token);
  console.log('✅ Token armazenado com sucesso');
};

/**
 * Remove o token JWT do localStorage
 */
export const removerToken = () => {
  localStorage.removeItem(CHAVE_TOKEN);
  console.log('🗑️ Token removido');
};

/**
 * Verifica se existe um token válido
 * @returns {boolean} True se o usuário está autenticado
 */
export const estaAutenticado = () => {
  const token = obterToken();
  if (!token) return false;
  return !tokenExpirado();
};

/**
 * Verifica se o token atual está expirado
 * @returns {boolean} True se o token está expirado
 */
export const tokenExpirado = () => {
  const token = obterToken();
  if (!token) {
    console.log('❌ Token não encontrado');
    return true;
  }
  
  try {
    const [, payloadBase64] = token.split('.');
    const payload = JSON.parse(atob(payloadBase64));
    const tempoExpiracao = payload.exp * 1000; // Converte para milissegundos
    const expirado = Date.now() >= tempoExpiracao;
    
    if (expirado) {
      console.log('⚠️ Token expirado');
    }
    
    return expirado;
  } catch (erro) {
    console.error('❌ Erro ao verificar expiração do token:', erro);
    return true;
  }
};

/**
 * Atualiza o token JWT atual
 * @returns {Promise<string>} Novo token JWT
 * @throws {Error} Erro ao atualizar o token
 */
export const atualizarToken = async () => {
  try {
    console.log('🔄 Atualizando token...');
    const resposta = await api.post('/api/autenticacao/refresh');
    const novoToken = resposta.data.token;
    definirToken(novoToken);
    console.log('✅ Token atualizado com sucesso');
    return novoToken;
  } catch (erro) {
    console.error('❌ Erro ao atualizar token:', erro);
    throw erro;
  }
};

/**
 * Configura interceptadores do Axios para gerenciamento automático do token
 */
export const configurarInterceptadoresAxios = () => {
  // Interceptador de requisições
  axios.interceptors.request.use(async (config) => {
    console.log('📝 Interceptando requisição:', config.url);
    
    if (tokenExpirado()) {
      try {
        const novoToken = await atualizarToken();
        config.headers.Authorization = `Bearer ${novoToken}`;
      } catch (erro) {
        console.error('❌ Falha ao atualizar token:', erro);
        removerToken();
        window.location.href = '/login';
        throw erro;
      }
    } else {
      const token = obterToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  }, (erro) => {
    console.error('❌ Erro na interceptação da requisição:', erro);
    return Promise.reject(erro);
  });

  // Interceptador de respostas
  axios.interceptors.response.use(
    (resposta) => resposta,
    async (erro) => {
      if (erro.response?.status === 401) {
        console.warn('⚠️ Resposta não autorizada recebida');
        removerToken();
        window.location.href = '/login';
      }
      return Promise.reject(erro);
    }
  );
};

/**
 * Inicializa o serviço de autenticação
 */
export const inicializarAutenticacao = () => {
  console.log('🔐 Inicializando serviço de autenticação');
  configurarInterceptadoresAxios();
};