/**
 * @fileoverview Componente de Barra Lateral do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Barra lateral de navegação principal com menu e botão de logout.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { 
  HomeIcon, 
  CalendarIcon, 
  UserGroupIcon, 
  UserIcon, 
  ClockIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  XMarkIcon 
} from '@heroicons/react/24/outline';
import { removerToken } from '../../servicos/autenticacao';
import logoIcon from '../../assets/images/icone_bbs2.png';

const ITENS_MENU = [
  { 
    nome: 'Dashboard', 
    icone: HomeIcon, 
    rota: '/dashboard',
    descricao: 'Painel Principal'
  },
  { 
    nome: 'Agendamentos', 
    icone: CalendarIcon, 
    rota: '/agendamentos',
    descricao: 'Gestão de Agendamentos'
  },
  { 
    nome: 'Clientes', 
    icone: UserGroupIcon, 
    rota: '/clientes',
    descricao: 'Gestão de Clientes'
  },
  { 
    nome: 'Funcionários', 
    icone: UserIcon, 
    rota: '/funcionarios',
    descricao: 'Gestão de Funcionários'
  },
  { 
    nome: 'Histórico', 
    icone: ClockIcon, 
    rota: '/historico',
    descricao: 'Histórico de Atendimentos'
  }
];

function BarraLateral() {
  const navegacao = useNavigate();
  const localizacao = useLocation();
  const [contraido, setContraido] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setContraido(false);
      } else {
        setContraido(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const realizarLogout = () => {
    removerToken();
    navegacao('/login');
  };

  const itemAtivo = (rota) => localizacao.pathname === rota;

  return (
    <div className={`
      relative flex flex-col h-screen
      ${contraido ? 'w-24' : 'w-72'} 
      bg-[#1a1f2b]
      transition-all duration-300 ease-in-out
      shadow-xl
      lg:w-72
    `}>
      {/* Botão de alternar - Movido para cima */}
      <button 
        onClick={() => setContraido(!contraido)}
        className="absolute -right-3 top-6 bg-gray-800 rounded-full p-1.5 
                   text-gray-300 hover:text-white focus:outline-none
                   shadow-lg hover:shadow-xl transition-all duration-300
                   hover:bg-gray-700 lg:hidden"
      >
        {contraido ? 
          <XMarkIcon className="h-6 w-6" /> : 
          <Bars3Icon className="h-6 w-6" />
        }
      </button>

      {/* Cabeçalho com Logo - Ajustado espaçamento quando contraído */}
      <div className={`
        flex flex-col items-center justify-center
        ${contraido ? 'pt-6 pb-4' : 'pt-8 pb-6'}
      `}>
        <div className={`
          transition-all duration-300 ease-in-out
          ${contraido ? 'w-20 h-20 p-2' : 'w-44 h-44 p-3'}
          relative group rounded-full
          lg:w-44 lg:h-44
          bg-[#1a1f2b]
        `}>
          <div className="w-full h-full relative">
            <img 
              src={logoIcon} 
              alt="BarbaBranca Logo"
              className="w-full h-full object-cover transition-transform duration-300 
                       group-hover:scale-105 p-1"
              style={{
                objectFit: 'contain',
                objectPosition: 'center center',
                transform: 'scale(1.2)'
              }}
            />
          </div>
        </div>
        
        {(!contraido || window.innerWidth >= 1024) && (
          <div className="mt-4 text-center">
            <h1 className="text-white text-2xl font-bold tracking-wider">
              BarbaBranca
            </h1>
            <div className="h-0.5 w-16 bg-blue-500 mx-auto mt-2" />
          </div>
        )}
      </div>

      {/* Menu de Navegação - Ajustado espaçamento */}
      <nav className={`
        flex-grow px-3
        ${contraido ? 'mt-3' : 'mt-6'}
      `} aria-label="Menu principal">
        <ul className="space-y-3">
          {ITENS_MENU.map((item) => (
            <li key={item.nome}>
              <Link
                to={item.rota}
                className={`
                  flex items-center px-4 py-3 rounded-lg
                  ${itemAtivo(item.rota) 
                    ? 'bg-blue-500/10 text-blue-400' 
                    : 'text-gray-300 hover:bg-gray-700/50'}
                  transition-all duration-200 group relative
                  ${contraido ? 'justify-center' : 'justify-start'}
                `}
                title={contraido ? item.nome : ''}
              >
                <item.icone 
                  className={`
                    ${contraido ? 'h-8 w-8' : 'h-7 w-7'} 
                    ${!contraido && 'mr-4'} 
                    ${itemAtivo(item.rota) ? 'text-blue-400' : ''}
                    group-hover:text-blue-400 transition-colors
                  `}
                />
                {(!contraido || window.innerWidth >= 1024) && (
                  <span className="font-medium tracking-wide text-lg">
                    {item.nome}
                  </span>
                )}
                {itemAtivo(item.rota) && (
                  <div className="absolute left-0 top-0 h-full w-1 bg-blue-500 rounded-r" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      {/* Botão de Logout */}
      <div className="p-4">
        <button
          onClick={realizarLogout}
          className={`
            flex items-center justify-center w-full py-3
            text-sm font-medium text-white bg-red-600
            hover:bg-red-700 rounded-lg
            focus:outline-none focus:ring-2 focus:ring-red-500
            focus:ring-offset-2 focus:ring-offset-gray-800
            shadow-lg hover:shadow-xl
            transition-all duration-200
            ${contraido ? 'px-2' : 'px-4'}
          `}
          title={contraido ? "Sair" : ""}
        >
          <ArrowLeftOnRectangleIcon className={`${contraido ? 'h-7 w-7' : 'h-6 w-6'}`} />
          {(!contraido || window.innerWidth >= 1024) && <span className="ml-2 text-base">Sair</span>}
        </button>
      </div>
    </div>
  );
}

export default BarraLateral;