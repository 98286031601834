/**
 * @fileoverview Lista de Clientes do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável pela listagem, filtro e gerenciamento
 * de clientes, incluindo aniversariantes.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { listarClientes, deletarCliente } from '../servicos/clientesServico';
import FormularioCliente from '../componentes/formularios/FormularioCliente';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash, FaEye, FaPlus } from 'react-icons/fa';

/**
 * @typedef {Object} Cliente
 * @property {number} id - ID do cliente
 * @property {string} nome - Nome completo
 * @property {string} cpf - CPF formatado
 * @property {string} email - Email
 * @property {string} telefone - Telefone formatado
 * @property {string} data_nascimento - Data de nascimento
 * @property {boolean} plano_mensal - Se possui plano mensal
 * @property {number} [valor_plano] - Valor do plano mensal
 */

/**
 * @typedef {Object} Paginacao
 * @property {number} pagina - Página atual
 * @property {number} limite - Itens por página
 * @property {number} total - Total de registros
 */

/**
 * Props do diálogo de confirmação
 * @typedef {Object} PropsDialogo
 * @property {boolean} estaAberto - Se o diálogo está visível
 * @property {Function} aoConfirmar - Callback de confirmação
 * @property {Function} aoCancelar - Callback de cancelamento
 * @property {string} mensagem - Mensagem a ser exibida
 */

/**
 * Componente de diálogo de confirmação
 * @param {PropsDialogo} props - Propriedades do componente 
 */
function DialogoConfirmacao({ estaAberto, aoConfirmar, aoCancelar, mensagem }) {
  if (!estaAberto) return null;

  return (
    <div 
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="titulo-dialogo"
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
          <h3 
            id="titulo-dialogo"
            className="text-lg font-bold mb-4"
          >
            Confirmar Exclusão
          </h3>
          <p className="mb-6">{mensagem}</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={aoCancelar}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition-colors duration-200"
            >
              Cancelar
            </button>
            <button
              onClick={aoConfirmar}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Componente principal de listagem de clientes
 * @returns {React.Component} Componente ListaClientes
 */
function ListaClientes() {
  // Estados
  const [clientes, setClientes] = useState([]);
  const [clienteParaEditar, setClienteParaEditar] = useState(null);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);
  const [clienteParaDeletar, setClienteParaDeletar] = useState(null);
  
  // Estado da paginação e filtros
  const [paginacao, setPaginacao] = useState({
    pagina: 1,
    limite: 10,
    total: 0
  });
  const [filtro, setFiltro] = useState('');
  const [ordenacao, setOrdenacao] = useState('nome');

  /**
   * Formata data considerando fuso horário local
   * @param {string} data - Data a ser formatada
   * @returns {string} Data formatada
   */
  const formatarDataLocal = useCallback((data) => {
    if (!data) return 'N/A';
    const dataLocal = new Date(data);
    dataLocal.setMinutes(dataLocal.getMinutes() + dataLocal.getTimezoneOffset());
    return dataLocal.toLocaleDateString('pt-BR');
  }, []);

  /**
   * Carrega lista de clientes com filtros e paginação
   */
  const carregarClientes = useCallback(async () => {
    setCarregando(true);
    setErro(null);
    
    try {
      console.log('📥 Carregando clientes...', {
        pagina: paginacao.pagina,
        limite: paginacao.limite,
        filtro,
        ordenacao
      });
  
      const { data, total } = await listarClientes(
        paginacao.pagina,
        paginacao.limite,
        filtro,
        ordenacao
      );
  
      if (!Array.isArray(data)) {
        console.error('❌ Dados inválidos:', data);
        throw new Error('Formato de dados inválido');
      }
  
      console.log(`✅ ${data.length} clientes carregados`);
      setClientes(data);
      setPaginacao(prev => ({ ...prev, total }));
    } catch (erro) {
      console.error('❌ Erro ao carregar clientes:', erro);
      setErro('Falha ao carregar a lista de clientes. Tente novamente.');
    } finally {
      setCarregando(false);
    }
  }, [paginacao.pagina, paginacao.limite, filtro, ordenacao]);

  // Carrega clientes ao montar componente ou quando filtros mudam
  useEffect(() => {
    carregarClientes();
  }, [carregarClientes]);

  /**
   * Salva cliente (novo ou editado)
   * @param {Cliente} clienteSalvo - Dados do cliente
   */
  const handleSalvar = useCallback(async (clienteSalvo) => {
    console.log('💾 Salvando cliente:', clienteSalvo);
    await carregarClientes();
    setMostrarFormulario(false);
    setClienteParaEditar(null);
  }, [carregarClientes]);

  /**
   * Inicia edição de cliente
   * @param {Cliente} cliente - Cliente a ser editado
   */
  const handleEditar = useCallback((cliente) => {
    console.log('📝 Iniciando edição:', cliente);
    setClienteParaEditar(cliente);
    setMostrarFormulario(true);
  }, []);

  /**
   * Inicia processo de deleção
   * @param {number} id - ID do cliente
   */
  const handleDeletar = useCallback((id) => {
    console.log('🗑️ Iniciando deleção:', id);
    setClienteParaDeletar(id);
  }, []);

  /**
   * Confirma deleção do cliente
   */
  const confirmarDeletar = useCallback(async () => {
    try {
      console.log('⚠️ Confirmando deleção:', clienteParaDeletar);
      
      await deletarCliente(clienteParaDeletar);
      
      // Atualiza a lista localmente
      setClientes(prev => prev.filter(c => c.id !== clienteParaDeletar));
      toast.success('Cliente removido com sucesso');
      
      // Recarrega a lista para garantir sincronização
      await carregarClientes();
      
    } catch (erro) {
      console.error('❌ Erro ao deletar:', erro);
      
      // Mensagem mais específica baseada no erro
      if (erro.message.includes('não encontrado')) {
        toast.error('Cliente não encontrado. A lista será atualizada.');
        await carregarClientes(); // Recarrega para sincronizar
      } else {
        toast.error('Não foi possível remover o cliente. Tente novamente.');
      }
    } finally {
      setClienteParaDeletar(null);
    }
  }, [clienteParaDeletar, carregarClientes]);

  /**
   * Funções de paginação e filtros
   */
  const handleChangePagina = useCallback((novaPagina) => {
    setPaginacao(prev => ({ ...prev, pagina: novaPagina }));
  }, []);
  
  const handleFiltroChange = useCallback((e) => {
    const novoFiltro = e.target.value;
    console.log('🔍 Aplicando filtro:', novoFiltro);
    setFiltro(novoFiltro);
    setPaginacao(prev => ({ ...prev, pagina: 1 }));
  }, []);
  
  const handleOrdenacaoChange = useCallback((e) => {
    const novaOrdenacao = e.target.value;
    console.log('⬆️ Alterando ordenação:', novaOrdenacao);
    setOrdenacao(novaOrdenacao);
    setPaginacao(prev => ({ ...prev, pagina: 1 }));
  }, []);

  /**
   * Calcula aniversariantes do dia e próximos
   * @returns {Object} Aniversariantes do dia e próximos
   */
  const calcularAniversariantes = useCallback(() => {
    const obterDiaMes = (data) => {
      const d = new Date(data);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      return {
        dia: d.getDate(),
        mes: d.getMonth()
      };
    };
  
    const hoje = new Date();
    const hojeLocal = obterDiaMes(hoje);
    
    console.log('📅 Calculando aniversariantes para:', {
      dia: hojeLocal.dia,
      mes: hojeLocal.mes + 1
    });
  
    // Aniversariantes do dia
    const aniversariantesDoDia = clientes.filter(cliente => {
      if (!cliente.data_nascimento) return false;
      const dataNascimento = obterDiaMes(cliente.data_nascimento);
      return dataNascimento.dia === hojeLocal.dia && 
             dataNascimento.mes === hojeLocal.mes;
    });

    // Próximos aniversariantes (7 dias)
    const proximosAniversariantes = clientes
      .filter(cliente => {
        if (!cliente.data_nascimento) return false;
        
        const dataNascimento = new Date(cliente.data_nascimento);
        dataNascimento.setMinutes(dataNascimento.getMinutes() + dataNascimento.getTimezoneOffset());
        
        const dataComparacao = new Date(
          hoje.getFullYear(),
          dataNascimento.getMonth(),
          dataNascimento.getDate()
        );
        
        if (dataComparacao < hoje) {
          dataComparacao.setFullYear(hoje.getFullYear() + 1);
        }
        
        const diasAteAniversario = Math.ceil(
          (dataComparacao - hoje) / (1000 * 60 * 60 * 24)
        );
        
        return diasAteAniversario > 0 && diasAteAniversario <= 7;
      })
      .sort((a, b) => {
        const dataA = new Date(a.data_nascimento);
        const dataB = new Date(b.data_nascimento);
        
        dataA.setMinutes(dataA.getMinutes() + dataA.getTimezoneOffset());
        dataB.setMinutes(dataB.getMinutes() + dataB.getTimezoneOffset());
        
        const compA = new Date(hoje.getFullYear(), dataA.getMonth(), dataA.getDate());
        const compB = new Date(hoje.getFullYear(), dataB.getMonth(), dataB.getDate());
        
        if (compA < hoje) compA.setFullYear(hoje.getFullYear() + 1);
        if (compB < hoje) compB.setFullYear(hoje.getFullYear() + 1);
        
        return compA - compB;
      });

    console.log('✨ Aniversariantes encontrados:', {
      hoje: aniversariantesDoDia.length,
      proximos: proximosAniversariantes.length
    });
  
    return { aniversariantesDoDia, proximosAniversariantes };
  }, [clientes]);

  const { aniversariantesDoDia, proximosAniversariantes } = calcularAniversariantes();

  // Early returns para estados de carregamento e erro
  if (carregando) {
    return (
      <div className="flex justify-center items-center py-10">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (erro) {
    return (
      <div className="text-center py-10">
        <p className="text-red-600 text-lg mb-4">{erro}</p>
        <button
          onClick={carregarClientes}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Tentar Novamente
        </button>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      {/* Cabeçalho */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Lista de Clientes</h1>
        <button
          onClick={() => {
            setClienteParaEditar(null);
            setMostrarFormulario(true);
          }}
          className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                   transition duration-300 ease-in-out flex items-center"
          aria-label="Adicionar novo cliente"
        >
          <FaPlus className="mr-2" />
          Novo Cliente
        </button>
      </div>

      {/* Modal de Formulário */}
      {mostrarFormulario && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-[600px] shadow-lg rounded-md bg-white">
            <FormularioCliente
              clienteParaEditar={clienteParaEditar}
              onSalvar={handleSalvar}
              onCancelar={() => {
                setMostrarFormulario(false);
                setClienteParaEditar(null);
              }}
            />
          </div>
        </div>
      )}
      
      {/* Diálogo de Confirmação */}
      <DialogoConfirmacao
        estaAberto={clienteParaDeletar !== null}
        aoConfirmar={confirmarDeletar}
        aoCancelar={() => setClienteParaDeletar(null)}
        mensagem="Tem certeza que deseja deletar este cliente? Esta ação não pode ser desfeita."
      />
      
      {/* Filtros */}
      <div className="mb-6 flex flex-wrap gap-4">
        <div className="flex-grow">
          <input
            type="text"
            placeholder="Buscar clientes..."
            value={filtro}
            onChange={handleFiltroChange}
            className="w-full p-2 border rounded-md hover:border-blue-500 
                     focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label="Filtrar clientes"
          />
        </div>
        <select
          value={ordenacao}
          onChange={handleOrdenacaoChange}
          className="p-2 border rounded-md hover:border-blue-500 
                   focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="Ordenar clientes"
        >
          <option value="nome">Ordenar por Nome</option>
          <option value="data_nascimento">Ordenar por Data de Nascimento</option>
        </select>
      </div>
  
      {/* Seção de Aniversariantes */}
      {aniversariantesDoDia.length > 0 && (
        <div className="mb-6 p-4 bg-yellow-100 rounded-md" role="alert">
          <h2 className="font-bold text-lg mb-2">🎂 Aniversariantes do Dia:</h2>
          <ul className="list-disc list-inside">
            {aniversariantesDoDia.map(cliente => (
              <li key={cliente.id} className="text-gray-700">{cliente.nome}</li>
            ))}
          </ul>
        </div>
      )}

      {proximosAniversariantes.length > 0 && (
        <div className="mb-6 p-4 bg-blue-100 rounded-md" role="alert">
          <h2 className="font-bold text-lg mb-2">📅 Próximos Aniversários (7 dias):</h2>
          <ul className="list-disc list-inside">
            {proximosAniversariantes.map(cliente => (
              <li key={cliente.id} className="text-gray-700">
                {cliente.nome} - {formatarDataLocal(cliente.data_nascimento)}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Tabela de Clientes */}
      <div className="shadow rounded-lg overflow-hidden">
        {clientes.length === 0 ? (
          <div className="bg-white p-8 text-center text-gray-600">
            <p>Nenhum cliente encontrado.</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    CPF
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Telefone
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data de Nascimento
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Plano Mensal
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Valor do Plano
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {clientes.map((cliente) => (
                  <tr key={cliente.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">{cliente.nome || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{cliente.cpf || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{cliente.email || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{cliente.telefone || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {formatarDataLocal(cliente.data_nascimento)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {cliente.plano_mensal ? 'Sim' : 'Não'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {cliente.plano_mensal 
                        ? cliente.valor_plano 
                          ? `R$ ${parseFloat(cliente.valor_plano).toFixed(2)}` 
                          : 'Valor não definido'
                        : 'N/A'
                      }
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleEditar(cliente)}
                        className="text-blue-600 hover:text-blue-900 mr-3 transition-colors"
                        title="Editar cliente"
                      >
                        <FaEdit className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeletar(cliente.id)}
                        className="text-red-600 hover:text-red-900 mr-3 transition-colors"
                        title="Deletar cliente"
                      >
                        <FaTrash className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => {/* Implementar visualização */}}
                        className="text-green-600 hover:text-green-900 transition-colors"
                        title="Ver detalhes"
                      >
                        <FaEye className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Paginação */}
      <div className="mt-6 flex justify-between items-center">
        <div className="text-sm text-gray-700">
          Mostrando {(paginacao.pagina - 1) * paginacao.limite + 1} a{' '}
          {Math.min(paginacao.pagina * paginacao.limite, paginacao.total)} de{' '}
          {paginacao.total} clientes
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => handleChangePagina(paginacao.pagina - 1)}
            disabled={paginacao.pagina === 1}
            className={`
              px-4 py-2 border rounded-md text-sm
              ${paginacao.pagina === 1 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50'}
              transition-colors duration-200
            `}
          >
            Anterior
          </button>
          <button
            onClick={() => handleChangePagina(paginacao.pagina + 1)}
            disabled={paginacao.pagina * paginacao.limite >= paginacao.total}
            className={`
              px-4 py-2 border rounded-md text-sm
              ${paginacao.pagina * paginacao.limite >= paginacao.total
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50'}
              transition-colors duration-200
            `}
          >
            Próxima
          </button>
        </div>
      </div>
    </div>
  );
}

export default ListaClientes;