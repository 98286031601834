/**
 * @fileoverview Serviço de clientes do BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 */

import api, { tratarErroAPI } from './api';

/**
 * Lista clientes com paginação e filtros
 * @async
 * @param {Object} params - Parâmetros de listagem
 * @returns {Promise<Object>} Dados dos clientes
 */
export const listarClientes = async (pagina = 1, limite = 10, filtro = '', ordenacao = '') => {
  try {
    console.log('📋 Listando clientes com parâmetros:', { pagina, limite, filtro, ordenacao });
    
    const resposta = await api.get('/clientes', {
      params: { pagina, limite, filtro, ordenacao }
    });

    // A resposta do backend tem formato { clientes: [], paginacao: {} }
    const { clientes = [], paginacao = {} } = resposta.data;
    
    return {
      data: clientes,
      total: paginacao.total || 0
    };
  } catch (erro) {
    console.error('❌ Erro ao listar clientes:', erro);
    throw new Error('Não foi possível listar os clientes');
  }
};

/**
 * Cria novo cliente
 * @async
 * @param {Object} dadosCliente - Dados do cliente
 * @returns {Promise<Object>} Cliente criado
 */
export const criarCliente = async (dadosCliente) => {
  try {
    console.log('📝 Criando cliente:', dadosCliente);
    const resposta = await api.post('/clientes', dadosCliente);
    console.log('✅ Cliente criado com sucesso');
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'criar cliente');
  }
};

/**
 * Atualiza dados do cliente
 * @async
 * @param {number} id - ID do cliente
 * @param {Object} dadosCliente - Novos dados
 * @returns {Promise<Object>} Cliente atualizado
 */
export const atualizarCliente = async (id, dadosCliente) => {
  try {
    console.log(`📝 Atualizando cliente ${id}:`, dadosCliente);
    const resposta = await api.put(`/clientes/${id}`, dadosCliente);
    console.log('✅ Cliente atualizado com sucesso');
    return resposta.data;
  } catch (erro) {
    tratarErroAPI(erro, 'atualizar cliente');
  }
};

/**
 * Remove um cliente
 * @async
 * @param {number} id - ID do cliente
 * @returns {Promise<Object>} Resultado da operação
 */
export const deletarCliente = async (id) => {
  try {
    console.log('🗑️ Removendo cliente:', id);
    const resposta = await api.delete(`/clientes/${id}`);
    console.log('✅ Cliente removido com sucesso');
    return { success: true, ...resposta.data };
  } catch (erro) {
    console.error('❌ Erro ao remover cliente:', erro);
    
    // Verifica se é erro 404 (não encontrado)
    if (erro.response?.status === 404) {
      throw new Error('Cliente não encontrado ou já foi removido');
    }
    
    throw new Error('Não foi possível remover o cliente');
  }
};