/**
 * @fileoverview Configuração e instância principal da API BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 */

import axios from 'axios';
import { 
  obterToken, 
  definirToken, 
  removerToken, 
  atualizarToken 
} from './autenticacao';

/**
 * Configurações da API
 * @constant {Object}
 */
const CONFIG_API = {
  BASE_URL: process.env.REACT_APP_API_URL || 'http://barbabranca.com.br/api',
  TIMEOUT: 10000,
  HEADERS_PADRAO: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

/**
 * Códigos de erro HTTP
 * @constant {Object}
 */
const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
};

/**
 * Rotas que precisam de atualização automática
 * @constant {Array<string>}
 */
const ROTAS_ATUALIZACAO = [
  '/agendamentos',
  '/clientes/ativos',
  '/historico'
];

/**
 * Instância do Axios configurada
 * @type {import('axios').AxiosInstance}
 */
const api = axios.create({
  baseURL: CONFIG_API.BASE_URL,
  withCredentials: true,
  timeout: CONFIG_API.TIMEOUT,
  headers: CONFIG_API.HEADERS_PADRAO
});

/**
 * Controla subscribers para atualizações
 * @type {Array<Function>}
 */
let listeners = [];

/**
 * Adiciona listener para atualizações
 * @param {Function} listener - Função a ser chamada
 */
export const adicionarListenerAtualizacao = (listener) => {
  listeners.push(listener);
};

/**
 * Remove listener de atualizações
 * @param {Function} listener - Função a ser removida
 */
export const removerListenerAtualizacao = (listener) => {
  listeners = listeners.filter(l => l !== listener);
};

/**
 * Notifica todos os listeners sobre atualização
 * @param {string} rota - Rota que foi atualizada
 */
const notificarAtualizacao = (rota) => {
  if (ROTAS_ATUALIZACAO.some(r => rota.includes(r))) {
    console.log('🔔 Notificando atualização:', rota);
    listeners.forEach(listener => listener(rota));
  }
};

/**
 * Mapeia erros HTTP para mensagens amigáveis
 * @type {Object.<number, string>}
 */
const MENSAGENS_ERRO = {
  [HTTP_STATUS.UNAUTHORIZED]: 'Sessão expirada. Por favor, faça login novamente.',
  [HTTP_STATUS.FORBIDDEN]: 'Você não tem permissão para acessar este recurso.',
  [HTTP_STATUS.NOT_FOUND]: 'Recurso não encontrado.',
  [HTTP_STATUS.SERVER_ERROR]: 'Erro interno do servidor. Tente novamente mais tarde.'
};

/**
 * Configura interceptadores de requisição e resposta
 */
const configurarInterceptadores = () => {
  // Interceptador de requisições
  api.interceptors.request.use(
    async (config) => {
      const inicioRequisicao = Date.now();
      config.metadata = { inicioRequisicao };

      console.log('📤 Enviando requisição:', {
        url: config.url,
        metodo: config.method?.toUpperCase(),
        params: config.params
      });

      const token = obterToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (erro) => {
      console.error('❌ Erro na requisição:', {
        url: erro.config?.url,
        erro: erro.message
      });
      return Promise.reject(erro);
    }
  );

  // Interceptador de respostas
  api.interceptors.response.use(
    (resposta) => {
      const duracao = Date.now() - resposta.config.metadata.inicioRequisicao;
      console.log('✅ Resposta recebida:', {
        url: resposta.config.url,
        status: resposta.status,
        duracao: `${duracao}ms`
      });

      // Notifica sobre mudanças em métodos que alteram dados
      if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(resposta.config.method?.toUpperCase())) {
        notificarAtualizacao(resposta.config.url);
      }

      return resposta;
    },
    async (erro) => {
      const requisicaoOriginal = erro.config;
      const status = erro.response?.status;
      
      console.error('❌ Erro na resposta:', {
        url: requisicaoOriginal?.url,
        status,
        mensagem: erro.message
      });

      // Tenta renovar token em caso de erro 401
      if (status === HTTP_STATUS.UNAUTHORIZED && !requisicaoOriginal._tentativaRenovacao) {
        requisicaoOriginal._tentativaRenovacao = true;
        
        try {
          console.log('🔄 Renovando token...');
          // Certifique-se que está usando a rota correta
          const novoToken = await atualizarToken();  // Esta função deve usar /api/autenticacao/refresh
          definirToken(novoToken);
          requisicaoOriginal.headers['Authorization'] = `Bearer ${novoToken}`;
          return api(requisicaoOriginal);
        } catch (erroRenovacao) {
          console.error('❌ Falha ao renovar token:', erroRenovacao);
          removerToken();
          redirecionarParaLogin();
          return Promise.reject(erroRenovacao);
        }
      }

      const mensagemErro = MENSAGENS_ERRO[status] || 'Erro desconhecido';
      erro.mensagemUsuario = mensagemErro;

      return Promise.reject(erro);
    }
  );
};

/**
 * Redireciona para a página de login
 */
const redirecionarParaLogin = () => {
  const urlAtual = window.location.pathname;
  window.location.href = `/login?redirect=${encodeURIComponent(urlAtual)}`;
};

/**
 * Trata erros da API de forma padronizada
 * @param {Error} erro - Erro capturado
 * @param {string} operacao - Nome da operação que falhou
 * @throws {Error} Erro tratado
 */
export const tratarErroAPI = (erro, operacao) => {
  console.error(`❌ Erro ao ${operacao}:`, {
    status: erro.response?.status,
    mensagem: erro.message,
    dados: erro.response?.data
  });

  const mensagemErro = erro.mensagemUsuario || 
    erro.response?.data?.erro || 
    erro.response?.data?.mensagem || 
    erro.message || 
    'Erro desconhecido';

  throw new Error(`Falha ao ${operacao}: ${mensagemErro}`);
};

// Configura interceptadores
configurarInterceptadores();

export default api;