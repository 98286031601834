/**
 * @fileoverview Página de Histórico do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Exibe o histórico de atendimentos realizados, com métricas
 * e filtros por período.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { 
  TrashIcon, 
  FunnelIcon, 
  ChartBarIcon, 
  ArrowPathIcon 
} from '@heroicons/react/24/outline';

// Componentes
import ListaHistorico from '../componentes/ListaHistorico';

// Serviços
import  historicoServico  from '../servicos/historicoServico';

/**
 * @typedef {Object} MetricasAno
 * @property {number} total_servicos - Total de serviços realizados
 * @property {number} receita_total - Receita total do ano
 * @property {number} ticket_medio - Ticket médio do ano
 * @property {number} total_clientes - Total de clientes atendidos
 */

/**
 * @typedef {Object} Metricas
 * @property {MetricasAno} metricasAno - Métricas do ano
 * @property {Array<Object>} servicosPopulares - Serviços mais realizados
 */

/**
 * @typedef {Object} RegistroHistorico
 * @property {number} id - ID do registro
 * @property {Date} data - Data do atendimento
 * @property {string} cliente - Nome do cliente
 * @property {string} servico - Serviço realizado
 * @property {number} valor - Valor do serviço
 */

/**
 * Configurações de períodos disponíveis
 * @constant {Object.<string, string>}
 */
const PERIODOS_TEXTO = {
  semana: 'nos últimos 7 dias',
  mes: 'nos últimos 30 dias',
  trimestre: 'nos últimos 90 dias',
  semestre: 'nos últimos 180 dias'
};

/**
 * Opções de períodos para seleção
 * @constant {Array<{valor: string, label: string}>}
 */
const PERIODOS = [
  { valor: 'semana', label: 'Última Semana' },
  { valor: 'mes', label: 'Último Mês' },
  { valor: 'trimestre', label: 'Últimos 3 Meses' },
  { valor: 'semestre', label: 'Últimos 6 Meses' }
];

/**
 * Estado inicial das métricas
 * @constant {Metricas}
 */
const METRICAS_INICIAL = {
  metricasAno: {
    total_servicos: 0,
    receita_total: 0,
    ticket_medio: 0,
    total_clientes: 0
  },
  servicosPopulares: []
};

/**
 * Configurações de animação
 * @constant {Object}
 */
const ANIMACAO = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.5 }
};

/**
 * Componente de modal de confirmação
 * @param {Object} props - Propriedades do componente
 * @returns {React.Component} Modal de confirmação
 */
const ModalConfirmacao = ({ onConfirm, onCancel }) => (
  <div 
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-titulo"
  >
    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h3 
        id="modal-titulo"
        className="text-lg font-medium mb-4"
      >
        Confirmar Exclusão
      </h3>
      <p className="text-gray-600 mb-6">
        Tem certeza que deseja excluir este registro? Esta ação não pode ser desfeita.
      </p>
      <div className="flex justify-end space-x-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-600 hover:text-gray-800 
                   transition-colors duration-200 focus:outline-none 
                   focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          aria-label="Cancelar exclusão"
        >
          Cancelar
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 
                   transition-colors duration-200 focus:outline-none 
                   focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          aria-label="Confirmar exclusão"
        >
          Confirmar Exclusão
        </button>
      </div>
    </div>
  </div>
);

/**
 * Componente de card de métrica
 * @param {Object} props - Propriedades do componente
 * @returns {React.Component} Card de métrica
 */
const CardMetrica = ({ titulo, valor, formatador }) => (
  <div className="bg-white p-4 rounded-lg shadow hover:shadow-md 
                  transition-shadow duration-200">
    <p className="text-sm text-gray-500">{titulo}</p>
    <p className="text-xl font-semibold">
      {formatador ? formatador(valor) : valor}
    </p>
  </div>
);

/**
 * Formata valor monetário
 * @param {number} valor - Valor a ser formatado
 * @returns {string} Valor formatado em BRL
 */
const formatarMoeda = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valor || 0);
};

/**
 * Formata texto plural/singular
 * @param {number} quantidade - Quantidade para formatação
 * @param {string} texto - Texto base
 * @returns {string} Texto formatado
 */
const formatarPlural = (quantidade, texto) => {
  return `${quantidade} ${texto}${quantidade !== 1 ? 's' : ''}`;
};

/**
 * Calcula média entre valores
 * @param {number} total - Valor total
 * @param {number} quantidade - Quantidade de itens
 * @returns {number} Média calculada
 */
const calcularMedia = (total, quantidade) => {
  if (!quantidade) return 0;
  return total / quantidade;
};

/**
 * Componente principal de Histórico
 * @returns {React.Component} Página de Histórico
 */
function Historico() {
  // Estados
  const [historico, setHistorico] = useState([]);
  const [metricas, setMetricas] = useState(METRICAS_INICIAL);
  const [totalMes, setTotalMes] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);
  const [periodoSelecionado, setPeriodoSelecionado] = useState('mes');
  const [mostrarConfirmacao, setMostrarConfirmacao] = useState(false);
  const [registroParaDeletar, setRegistroParaDeletar] = useState(null);

  /**
   * Carrega métricas gerais
   */
  const carregarMetricas = async () => {
    try {
      console.log('📊 Carregando métricas...');
      const dadosMetricas = await historicoServico.buscarMetricas();
      
      if (dadosMetricas?.metricasAno?.total_servicos > 0) {
        setMetricas(dadosMetricas);
        console.log('✅ Métricas carregadas com sucesso');
      } else {
        setMetricas(METRICAS_INICIAL);
        console.log('ℹ️ Nenhuma métrica encontrada');
      }
    } catch (erro) {
      console.error('❌ Erro ao carregar métricas:', erro);
      setMetricas(METRICAS_INICIAL);
    }
  };

  /**
   * Carrega histórico do período selecionado
   */
  const carregarHistorico = useCallback(async () => {
    try {
      setCarregando(true);
      setErro(null);
      
      console.log('📅 Carregando histórico:', periodoSelecionado);
      const dados = await historicoServico.buscarHistorico(periodoSelecionado);
      
      if (dados?.historico?.length > 0) {
        setHistorico(dados.historico.map(registro => ({
          ...registro,
          data: new Date(registro.data)
        })));
        setTotalMes(dados.metricas.totalPeriodo || 0);
        console.log(`✅ ${dados.historico.length} registros encontrados`);
      } else {
        setHistorico([]);
        setTotalMes(0);
        console.log('ℹ️ Nenhum registro encontrado');
      }
    } catch (erro) {
      console.error('❌ Erro ao carregar histórico:', erro);
      setErro(`Erro ao carregar histórico: ${erro.message}`);
      setHistorico([]);
      setTotalMes(0);
    } finally {
      setCarregando(false);
    }
  }, [periodoSelecionado]);

  // Carrega dados iniciais
  useEffect(() => {
    carregarHistorico();
    carregarMetricas();
  }, [carregarHistorico]);

  /**
   * Remove um registro do histórico
   * @param {number} id - ID do registro
   */
  const handleDeletar = async (id) => {
    if (!id) return;
    
    try {
      setCarregando(true);
      setErro(null);
  
      console.log('🗑️ Removendo registro:', id);
      await historicoServico.deletarRegistro(id);
      
      await Promise.all([
        carregarHistorico(),
        carregarMetricas()
      ]);
  
      console.log('✅ Registro removido com sucesso');
    } catch (erro) {
      console.error('❌ Erro ao remover registro:', erro);
      setErro(`Erro ao remover registro: ${erro.message}`);
    } finally {
      setCarregando(false);
    }
  };

  const temDadosValidos = historico.length > 0 && totalMes > 0;
  const temMetricasValidas = metricas?.metricasAno?.total_servicos > 0;

  return (
    <motion.div {...ANIMACAO} className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Cabeçalho */}
        <header className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">
              Histórico de Atendimentos
            </h1>
            {historico.length > 0 && (
              <p className="text-sm text-gray-500 mt-1">
                {PERIODOS_TEXTO[periodoSelecionado]}
              </p>
            )}
          </div>
          
          <div className="flex items-center space-x-4">
            <button
              onClick={() => {
                carregarHistorico();
                carregarMetricas();
              }}
              className="p-2 text-gray-400 hover:text-gray-500 
                       transition-colors duration-200"
              title="Atualizar dados"
              aria-label="Atualizar dados"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </button>
            
            <select
              value={periodoSelecionado}
              onChange={(e) => setPeriodoSelecionado(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm 
                       focus:border-indigo-500 focus:ring-indigo-500"
              aria-label="Selecionar período"
            >
              {PERIODOS.map((periodo) => (
                <option key={periodo.valor} value={periodo.valor}>
                  {periodo.label}
                </option>
              ))}
            </select>
          </div>
        </header>

        {/* Mensagem de Erro */}
        {erro && (
          <div 
            className="mb-4 p-4 bg-red-100 border border-red-400 
                     text-red-700 rounded"
            role="alert"
          >
            {erro}
          </div>
        )}

        {/* Resumo do Período */}
        <div className="mb-4">
          <p className="text-sm text-gray-500">
            {historico.length === 0 
              ? `Nenhum serviço realizado ${PERIODOS_TEXTO[periodoSelecionado]}`
              : formatarPlural(historico.length, 'serviço') + 
                ` realizado ${PERIODOS_TEXTO[periodoSelecionado]}`
            }
          </p>
        </div>

        {/* Cards de Métricas do Período */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
          <CardMetrica 
            titulo={`Total ${PERIODOS_TEXTO[periodoSelecionado]}`}
            valor={totalMes}
            formatador={formatarMoeda}
          />
          <CardMetrica 
            titulo={`Média por Serviço ${PERIODOS_TEXTO[periodoSelecionado]}`}
            valor={calcularMedia(totalMes, historico.length)}
            formatador={formatarMoeda}
          />
          <CardMetrica 
            titulo={`Serviços Realizados ${PERIODOS_TEXTO[periodoSelecionado]}`}
            valor={historico.length}
          />
        </div>

        {/* Métricas do Ano */}
        {temMetricasValidas && (
          <section className="mb-6" aria-label="Métricas anuais">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Métricas do Ano {new Date().getFullYear()}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <CardMetrica 
                titulo="Serviços Realizados"
                valor={metricas.metricasAno.total_servicos}
              />
              <CardMetrica 
                titulo="Clientes Atendidos"
                valor={metricas.metricasAno.total_clientes}
              />
              <CardMetrica 
                titulo="Receita Total"
                valor={metricas.metricasAno.receita_total}
                formatador={formatarMoeda}
              />
              <CardMetrica 
                titulo="Ticket Médio"
                valor={metricas.metricasAno.ticket_medio}
                formatador={formatarMoeda}
              />
            </div>
          </section>
        )}

        {/* Lista de Registros */}
        <section className="mt-8" aria-label="Histórico de atendimentos">
          {carregando ? (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 
                           border-b-2 border-indigo-500" />
            </div>
          ) : (
            <ListaHistorico 
              registros={historico} 
              onDelete={(id) => {
                setRegistroParaDeletar(id);
                setMostrarConfirmacao(true);
              }}
            />
          )}
        </section>
      </div>

      {/* Modal de Confirmação */}
      {mostrarConfirmacao && (
        <ModalConfirmacao
          onConfirm={() => {
            handleDeletar(registroParaDeletar);
            setMostrarConfirmacao(false);
            setRegistroParaDeletar(null);
          }}
          onCancel={() => {
            setMostrarConfirmacao(false);
            setRegistroParaDeletar(null);
          }}
        />
      )}
    </motion.div>
  );
}

export default Historico;