/**
 * @fileoverview Formulário de cadastro e edição de clientes
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável pelo formulário de clientes,
 * incluindo validações e formatações.
 */

import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { criarCliente, atualizarCliente } from '../../servicos/clientesServico';

/**
 * @typedef {Object} Cliente
 * @property {string} nome - Nome completo
 * @property {string} cpf - CPF formatado
 * @property {string} telefone - Telefone formatado
 * @property {string} email - Email
 * @property {string} data_nascimento - Data de nascimento (YYYY-MM-DD)
 * @property {boolean} plano_mensal - Se possui plano mensal
 * @property {number} [valor_plano] - Valor do plano mensal
 */

/**
 * @typedef {Object} PropsFormulario
 * @property {Cliente} [clienteParaEditar] - Cliente sendo editado
 * @property {Function} onSalvar - Callback após salvar
 * @property {Function} onCancelar - Callback para cancelar
 */

/**
 * Regras de validação do formulário
 */
const REGRAS_VALIDACAO = {
  nome: {
    required: 'Nome é obrigatório',
    minLength: { value: 3, message: 'Nome deve ter no mínimo 3 caracteres' }
  },
  cpf: {
    required: 'CPF é obrigatório',
    validate: value => value.replace(/[^\d]/g, '').length === 11 || 'CPF inválido'
  },
  telefone: {
    required: 'Telefone é obrigatório',
    validate: value => value.replace(/[^\d]/g, '').length === 11 || 'Telefone inválido'
  },
  email: {
    required: 'Email é obrigatório',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Email inválido'
    }
  },
  data_nascimento: {
    required: 'Data de nascimento é obrigatória',
    validate: {
      dataFutura: value => {
        const data = new Date(value);
        return data <= new Date() || 'Data não pode ser futura';
      },
      idadeMinima: value => {
        const data = new Date(value);
        const idade = new Date().getFullYear() - data.getFullYear();
        return idade >= 18 || 'Cliente deve ter pelo menos 18 anos';
      }
    }
  },
  valor_plano: {
    validate: (value, formValues) => {
      if (formValues.plano_mensal) {
        if (!value) return 'Valor do plano é obrigatório';
        if (Number(value) <= 0) return 'Valor deve ser maior que zero';
      }
      return true;
    }
  }
};

/**
 * Componente de input formatado
 */
const InputFormatado = React.forwardRef((props, ref) => (
  <PatternFormat {...props} getInputRef={ref} />
));

InputFormatado.displayName = 'InputFormatado';

/**
 * Componente de formulário de cliente
 * @param {PropsFormulario} props - Propriedades do componente
 * @returns {React.Component} Formulário de Cliente
 */
function FormularioCliente({ clienteParaEditar, onSalvar, onCancelar }) {
  const { 
    control, 
    handleSubmit, 
    setValue, 
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      nome: '',
      cpf: '',
      telefone: '',
      email: '',
      data_nascimento: '',
      plano_mensal: false,
      valor_plano: ''
    }
  });

  const planoMensal = watch('plano_mensal');

  /**
   * Ajusta data para fuso horário local
   */
  const ajustarDataParaLocalTime = (data) => {
    if (!data) return '';
    const dataObj = new Date(data);
    dataObj.setMinutes(dataObj.getMinutes() + dataObj.getTimezoneOffset());
    return dataObj.toISOString().split('T')[0];
  };

  // Carrega dados do cliente para edição
  useEffect(() => {
    if (clienteParaEditar) {
      console.log('📝 Carregando dados do cliente:', clienteParaEditar);
      
      Object.keys(clienteParaEditar).forEach(key => {
        let valor = clienteParaEditar[key];
        
        switch(key) {
          case 'data_nascimento':
            valor = ajustarDataParaLocalTime(valor);
            break;
          case 'plano_mensal':
            valor = Boolean(valor);
            break;
          case 'valor_plano':
            valor = valor ? Number(valor) : '';
            break;
          default:
            break;
        }
        
        setValue(key, valor);
      });
    }
  }, [clienteParaEditar, setValue]);

  /**
   * Processa o envio do formulário
   */
  const onSubmit = async (dados) => {
    console.log('📤 Enviando dados do cliente:', dados);
    
    try {
      const dadosAjustados = {
        ...dados,
        data_nascimento: dados.data_nascimento ? 
          new Date(dados.data_nascimento).toISOString().split('T')[0] : null,
        valor_plano: dados.plano_mensal ? Number(dados.valor_plano) : null
      };

      let resultado;
      if (clienteParaEditar) {
        resultado = await atualizarCliente(clienteParaEditar.id, dadosAjustados);
        toast.success('Cliente atualizado com sucesso!');
      } else {
        resultado = await criarCliente(dadosAjustados);
        toast.success('Cliente cadastrado com sucesso!');
      }

      console.log('✅ Cliente salvo:', resultado);
      onSalvar(resultado);
    } catch (erro) {
      console.error('❌ Erro ao salvar cliente:', erro);
      
      if (erro.response?.status === 409) {
        toast.error('CPF ou email já cadastrado');
      } else if (erro.response?.status === 401) {
        toast.error('Sua sessão expirou. Por favor, faça login novamente.');
        window.location.href = '/login';
      } else {
        toast.error('Erro ao salvar cliente. Tente novamente.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {/* Campo Nome */}
      <div>
        <label htmlFor="nome" className="block text-sm font-medium text-gray-700">
          Nome
        </label>
        <Controller
          name="nome"
          control={control}
          rules={REGRAS_VALIDACAO.nome}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              id="nome"
              disabled={isSubmitting}
              placeholder="Nome completo"
              className={`
                mt-1 block w-full rounded-md shadow-sm
                ${isSubmitting ? 'opacity-50' : ''}
                ${errors.nome ? 'border-red-300' : 'border-gray-300'}
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                focus:ring-opacity-50
              `}
            />
          )}
        />
        {errors.nome && (
          <p className="mt-1 text-sm text-red-600">{errors.nome.message}</p>
        )}
      </div>

      {/* Campo CPF */}
      <div>
        <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">
          CPF
        </label>
        <Controller
          name="cpf"
          control={control}
          rules={REGRAS_VALIDACAO.cpf}
          render={({ field }) => (
            <InputFormatado
              {...field}
              format="###.###.###-##"
              mask="_"
              id="cpf"
              disabled={isSubmitting}
              placeholder="000.000.000-00"
              className={`
                mt-1 block w-full rounded-md shadow-sm
                ${isSubmitting ? 'opacity-50' : ''}
                ${errors.cpf ? 'border-red-300' : 'border-gray-300'}
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                focus:ring-opacity-50
              `}
            />
          )}
        />
        {errors.cpf && (
          <p className="mt-1 text-sm text-red-600">{errors.cpf.message}</p>
        )}
      </div>

      {/* Campo Telefone */}
      <div>
        <label htmlFor="telefone" className="block text-sm font-medium text-gray-700">
          Telefone
        </label>
        <Controller
          name="telefone"
          control={control}
          rules={REGRAS_VALIDACAO.telefone}
          render={({ field }) => (
            <InputFormatado
              {...field}
              format="(##) #####-####"
              mask="_"
              id="telefone"
              disabled={isSubmitting}
              placeholder="(00) 00000-0000"
              className={`
                mt-1 block w-full rounded-md shadow-sm
                ${isSubmitting ? 'opacity-50' : ''}
                ${errors.telefone ? 'border-red-300' : 'border-gray-300'}
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                focus:ring-opacity-50
              `}
            />
          )}
        />
        {errors.telefone && (
          <p className="mt-1 text-sm text-red-600">{errors.telefone.message}</p>
        )}
      </div>

      {/* Campo Email */}
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <Controller
          name="email"
          control={control}
          rules={REGRAS_VALIDACAO.email}
          render={({ field }) => (
            <input
              {...field}
              type="email"
              id="email"
              disabled={isSubmitting}
              placeholder="exemplo@email.com"
              className={`
                mt-1 block w-full rounded-md shadow-sm
                ${isSubmitting ? 'opacity-50' : ''}
                ${errors.email ? 'border-red-300' : 'border-gray-300'}
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                focus:ring-opacity-50
              `}
            />
          )}
        />
        {errors.email && (
          <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
        )}
      </div>

      {/* Campo Data de Nascimento */}
      <div>
        <label htmlFor="data_nascimento" className="block text-sm font-medium text-gray-700">
          Data de Nascimento
        </label>
        <Controller
          name="data_nascimento"
          control={control}
          rules={REGRAS_VALIDACAO.data_nascimento}
          render={({ field }) => (
            <input
              {...field}
              type="date"
              id="data_nascimento"
              disabled={isSubmitting}
              max={ajustarDataParaLocalTime(new Date())}
              className={`
                mt-1 block w-full rounded-md shadow-sm
                ${isSubmitting ? 'opacity-50' : ''}
                ${errors.data_nascimento ? 'border-red-300' : 'border-gray-300'}
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                focus:ring-opacity-50
              `}
            />
          )}
        />
        {errors.data_nascimento && (
          <p className="mt-1 text-sm text-red-600">{errors.data_nascimento.message}</p>
        )}
      </div>

      {/* Campo Plano Mensal */}
      <div>
        <label htmlFor="plano_mensal" className="flex items-center">
          <Controller
            name="plano_mensal"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="checkbox"
                id="plano_mensal"
                disabled={isSubmitting}
                checked={field.value}
                className={`
                  rounded border-gray-300 text-blue-600 shadow-sm
                  focus:border-blue-300 focus:ring focus:ring-blue-200 
                  focus:ring-opacity-50 mr-2
                  ${isSubmitting ? 'opacity-50' : ''}
                `}
              />
            )}
          />
          <span className="text-sm font-medium text-gray-700">Plano Mensal</span>
        </label>
      </div>

      {/* Campo Valor do Plano */}
      {planoMensal && (
        <div>
          <label htmlFor="valor_plano" className="block text-sm font-medium text-gray-700">
            Valor do Plano Mensal
          </label>
          <Controller
            name="valor_plano"
            control={control}
            rules={REGRAS_VALIDACAO.valor_plano}
            render={({ field }) => (
              <input
                {...field}
                type="number"
                step="0.01"
                min="0"
                id="valor_plano"
                disabled={isSubmitting}
                placeholder="0,00"
                className={`
                  mt-1 block w-full rounded-md shadow-sm
                  ${isSubmitting ? 'opacity-50' : ''}
                  ${errors.valor_plano ? 'border-red-300' : 'border-gray-300'}
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                  focus:ring-opacity-50
                `}
              />
            )}
          />
          {errors.valor_plano && (
            <p className="mt-1 text-sm text-red-600">{errors.valor_plano.message}</p>
          )}
        </div>
      )}

      {/* Botões */}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancelar}
          disabled={isSubmitting}
          className={`
            px-4 py-2 border border-gray-300 rounded-md shadow-sm 
            text-sm font-medium text-gray-700 bg-white
            ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
            transition-colors duration-200
          `}
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`
            px-4 py-2 border border-transparent rounded-md shadow-sm 
            text-sm font-medium text-white bg-blue-600 
            ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}
            focus:outline-none focus:ring-2 focus:ring-offset-2 
            focus:ring-blue-500
            transition-colors duration-200
          `}
          aria-busy={isSubmitting}
        >
          {isSubmitting 
            ? 'Salvando...' 
            : clienteParaEditar 
              ? 'Atualizar Cliente' 
              : 'Cadastrar Cliente'
          }
        </button>
      </div>
    </form>
  );
}

export default FormularioCliente;