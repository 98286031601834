/**
 * @fileoverview Lista de Agendamentos do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável pela listagem, filtragem e paginação
 * de agendamentos.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { 
  CalendarIcon, 
  MagnifyingGlassIcon, 
  CheckCircleIcon 
} from '@heroicons/react/24/outline';
import ReactPaginate from 'react-paginate';
import { motion } from 'framer-motion';

/**
 * @typedef {Object} Agendamento
 * @property {number} id - ID do agendamento
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço a ser realizado
 * @property {number} valor - Valor do serviço
 * @property {string} data - Data e hora do agendamento
 * @property {string} status - Status do agendamento
 */

/**
 * @typedef {Object} PropsListaAgendamentos
 * @property {Array<Agendamento>} agendamentos - Lista de agendamentos
 * @property {Function} aoSelecionarAgendamento - Callback ao selecionar
 * @property {Function} aoConcluirAgendamento - Callback ao concluir
 */

/**
 * Configurações de animação
 * @constant {Object}
 */
const ANIMACOES = {
  container: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.5 }
  },
  item: {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.3 }
  }
};

/**
 * Configurações de paginação
 * @constant {Object}
 */
const CONFIGURACAO_PAGINACAO = {
  itensPorPagina: 5,
  marginPagesDisplayed: 2,
  pageRangeDisplayed: 5,
  classNames: {
    container: 'pagination flex justify-center mt-4 space-x-2',
    page: `
      bg-white border border-gray-300 text-gray-500 
      hover:bg-gray-50 relative inline-flex items-center 
      px-4 py-2 text-sm font-medium
    `,
    active: 'bg-indigo-50 border-indigo-500 text-indigo-600 z-10'
  }
};

/**
 * Formata valor monetário
 * @param {number} valor - Valor a ser formatado
 * @returns {string} Valor formatado em BRL
 */
const formatarValor = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valor || 0);
};

/**
 * Formata data e hora
 * @param {string} data - Data a ser formatada
 * @returns {string} Data formatada
 */
const formatarData = (data) => {
  try {
    if (!data) return 'Data não disponível';
    const dataObj = new Date(data);
    if (isNaN(dataObj.getTime())) {
      console.error('❌ Data inválida:', data);
      return 'Data inválida';
    }
    return format(dataObj, "d 'de' MMMM 'às' HH:mm", { locale: ptBR });
  } catch (erro) {
    console.error('❌ Erro ao formatar data:', erro);
    return 'Data inválida';
  }
};

/**
 * Componente de listagem de agendamentos
 * @param {PropsListaAgendamentos} props - Propriedades do componente
 * @returns {React.Component} Componente ListaAgendamentos
 */
function ListaAgendamentos({ 
  agendamentos = [], 
  aoSelecionarAgendamento, 
  aoConcluirAgendamento 
}) {
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [paginaAtual, setPaginaAtual] = useState(0);

  /**
   * Filtra agendamentos pelo termo de pesquisa
   * @returns {Array<Agendamento>} Agendamentos filtrados
   */
  const agendamentosFiltrados = agendamentos.filter(agendamento => {
    const termo = termoPesquisa.toLowerCase();
    return (
      (agendamento.nome_cliente?.toLowerCase() || '').includes(termo) ||
      (agendamento.servico?.toLowerCase() || '').includes(termo)
    );
  });

  const contagemPaginas = Math.ceil(
    agendamentosFiltrados.length / CONFIGURACAO_PAGINACAO.itensPorPagina
  );
  
  const offset = paginaAtual * CONFIGURACAO_PAGINACAO.itensPorPagina;
  const agendamentosAtuais = agendamentosFiltrados.slice(
    offset, 
    offset + CONFIGURACAO_PAGINACAO.itensPorPagina
  );

  /**
   * Manipula mudança de página
   * @param {Object} param0 - Objeto com página selecionada
   */
  const handleMudancaPagina = ({ selected }) => {
    console.log('📄 Mudando para página:', selected + 1);
    setPaginaAtual(selected);
  };

  /**
   * Manipula conclusão de agendamento
   * @param {Event} evento - Evento do clique
   * @param {Agendamento} agendamento - Agendamento a ser concluído
   */
  const handleConcluir = (evento, agendamento) => {
    evento.stopPropagation();
    if (aoConcluirAgendamento) {
      console.log('✅ Concluindo agendamento:', agendamento.id);
      aoConcluirAgendamento(agendamento.id);
    }
  };

  if (!agendamentos.length) {
    return (
      <div className="text-center py-8 text-gray-500">
        Nenhum agendamento encontrado.
      </div>
    );
  }

  return (
    <div className="mt-8">
      <h2 className="text-lg font-medium text-gray-900">Agendamentos</h2>
      
      {/* Campo de Busca */}
      <div className="mt-4 mb-4 relative">
        <input
          type="text"
          placeholder="Buscar por cliente ou serviço..."
          value={termoPesquisa}
          onChange={(e) => setTermoPesquisa(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md
                    focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          aria-label="Buscar agendamentos"
        />
        <MagnifyingGlassIcon 
          className="h-5 w-5 text-gray-400 absolute right-3 top-3" 
          aria-hidden="true"
        />
      </div>

      {/* Lista de Agendamentos */}
      <motion.div {...ANIMACOES.container} className="bg-white shadow rounded-lg overflow-hidden">
        <ul className="divide-y divide-gray-200" role="list">
          {agendamentosAtuais.map((agendamento) => (
            <motion.li 
              key={agendamento.id}
              {...ANIMACOES.item}
              onClick={() => aoSelecionarAgendamento(agendamento)}
              className="px-4 py-4 sm:px-6 hover:bg-gray-50 cursor-pointer
                         transition-colors duration-200"
              role="button"
              aria-label={`Ver detalhes do agendamento de ${agendamento.nome_cliente}`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    {agendamento.nome_cliente || 'Cliente não especificado'}
                  </p>
                  <p className="ml-4 text-sm font-medium text-gray-900">
                    {formatarValor(agendamento.valor)}
                  </p>
                </div>
                
                <div className="ml-2 flex items-center space-x-2">
                  <span className="px-2 inline-flex text-xs leading-5 
                                 font-semibold rounded-full bg-green-100 
                                 text-green-800">
                    {agendamento.servico}
                  </span>
                  
                  {agendamento.status !== 'concluido' ? (
                    <button
                      onClick={(e) => handleConcluir(e, agendamento)}
                      className="px-2 py-1 text-xs font-medium text-white 
                               bg-green-600 rounded hover:bg-green-700 
                               focus:outline-none focus:ring-2 
                               focus:ring-offset-2 focus:ring-green-500
                               transition-colors duration-200"
                      aria-label={`Concluir agendamento de ${agendamento.nome_cliente}`}
                    >
                      Concluir
                    </button>
                  ) : (
                    <span className="flex items-center text-green-600">
                      <CheckCircleIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                      Concluído
                    </span>
                  )}
                </div>
              </div>
              
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">
                  <p className="flex items-center text-sm text-gray-500">
                    <CalendarIcon 
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" 
                      aria-hidden="true" 
                    />
                    {formatarData(agendamento.data)}
                  </p>
                </div>
                <div className="mt-2 sm:mt-0">
                  <p className="text-sm text-gray-500">
                    Status: {agendamento.status || 'pendente'}
                  </p>
                </div>
              </div>
            </motion.li>
          ))}
        </ul>
      </motion.div>

      {/* Paginação */}
      <ReactPaginate
        previousLabel="Anterior"
        nextLabel="Próximo"
        breakLabel="..."
        pageCount={contagemPaginas}
        marginPagesDisplayed={CONFIGURACAO_PAGINACAO.marginPagesDisplayed}
        pageRangeDisplayed={CONFIGURACAO_PAGINACAO.pageRangeDisplayed}
        onPageChange={handleMudancaPagina}
        containerClassName={CONFIGURACAO_PAGINACAO.classNames.container}
        pageClassName={CONFIGURACAO_PAGINACAO.classNames.page}
        previousClassName={CONFIGURACAO_PAGINACAO.classNames.page}
        nextClassName={CONFIGURACAO_PAGINACAO.classNames.page}
        breakClassName={CONFIGURACAO_PAGINACAO.classNames.page}
        activeClassName={CONFIGURACAO_PAGINACAO.classNames.active}
        ariaLabel="Navegação de páginas"
      />
    </div>
  );
}

// Verificação de tipos em desenvolvimento
if (process.env.NODE_ENV === 'development') {
  ListaAgendamentos.propTypes = {
    agendamentos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        nome_cliente: PropTypes.string,
        servico: PropTypes.string.isRequired,
        valor: PropTypes.number,
        data: PropTypes.string.isRequired,
        status: PropTypes.string
      })
    ).isRequired,
    aoSelecionarAgendamento: PropTypes.func.isRequired,
    aoConcluirAgendamento: PropTypes.func
  };
}

export default ListaAgendamentos;