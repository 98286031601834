/**
 * @fileoverview Serviço de Histórico do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Gerencia operações relacionadas ao histórico de atendimentos,
 * incluindo consultas, métricas e deleção.
 */

import api from './api';

/**
 * @typedef {Object} HistoricoItem
 * @property {number} id - ID do registro
 * @property {string} data - Data do atendimento
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço realizado
 * @property {number} valor - Valor do serviço
 */

/**
 * @typedef {Object} MetricasHistorico
 * @property {number} total_atendimentos - Total de atendimentos
 * @property {number} receita_total - Receita total
 * @property {number} ticket_medio - Ticket médio
 */

/**
 * Períodos válidos para consulta
 * @constant {Object.<string, string>}
 */
const PERIODOS = {
  DIA: 'dia',
  SEMANA: 'semana',
  MES: 'mes',
  ANO: 'ano'
};

/**
 * Serviço de manipulação do histórico
 */
const historicoServico = {
  /**
   * Busca histórico de atendimentos por período
   * @async
   * @param {string} [periodo='mes'] - Período desejado (dia, semana, mes, ano)
   * @returns {Promise<Array<HistoricoItem>>} Lista de registros do histórico
   * @throws {Error} Erro ao buscar histórico
   */
  async buscarHistorico(periodo = PERIODOS.MES) {
    try {
      console.log('📅 Buscando histórico:', periodo);
      
      // Validação do período
      if (!Object.values(PERIODOS).includes(periodo)) {
        throw new Error('Período inválido');
      }

      const params = new URLSearchParams({
        _: Date.now() // Evita cache
      });

      const resposta = await api.get(
        `/historico/${periodo}?${params.toString()}`
      );

      console.log(`✅ ${resposta.data.length} registros encontrados`);
      return resposta.data;
    } catch (erro) {
      console.error('❌ Erro ao buscar histórico:', erro);
      throw new Error('Não foi possível buscar o histórico');
    }
  },

  /**
   * Busca métricas resumidas do histórico
   * @async
   * @returns {Promise<MetricasHistorico>} Métricas do histórico
   * @throws {Error} Erro ao buscar métricas
   */
  async buscarMetricas() {
    try {
      console.log('📊 Buscando métricas do histórico...');
      
      const params = new URLSearchParams({
        _: Date.now() // Evita cache
      });

      const resposta = await api.get(
        `/historico/metricas/resumo?${params.toString()}`
      );

      console.log('✅ Métricas obtidas com sucesso');
      return resposta.data;
    } catch (erro) {
      console.error('❌ Erro ao buscar métricas:', erro);
      throw new Error('Não foi possível buscar as métricas');
    }
  },

  /**
   * Remove um registro do histórico
   * @async
   * @param {number} id - ID do registro
   * @returns {Promise<Object>} Confirmação da deleção
   * @throws {Error} Erro ao deletar registro
   */
  async deletarRegistro(id) {
    try {
      if (!id) {
        throw new Error('ID do registro é obrigatório');
      }

      console.log('🗑️ Removendo registro:', id);
      const resposta = await api.delete(`/historico/${id}`);
      
      console.log('✅ Registro removido com sucesso');
      return resposta.data;
    } catch (erro) {
      console.error('❌ Erro ao deletar registro:', erro);
      throw new Error('Não foi possível remover o registro');
    }
  }
};

export default historicoServico;