/**
 * @fileoverview Hook de atualização automática da API
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 */

import { useEffect } from 'react';
import { adicionarListenerAtualizacao, removerListenerAtualizacao } from '../servicos/api';

/**
 * Hook para usar atualização automática
 * @param {string} rota - Rota a ser monitorada
 * @param {Function} callback - Função a ser chamada
 */
export const useAtualizacaoAPI = (rota, callback) => {
  useEffect(() => {
    const listener = (rotaAtualizada) => {
      if (rotaAtualizada === rota) {
        console.log('🔄 Atualizando dados após mudança:', rota);
        callback();
      }
    };

    adicionarListenerAtualizacao(listener);
    return () => removerListenerAtualizacao(listener);
  }, [rota, callback]);
};