/**
 * @fileoverview Serviço de Agendamentos do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Gerencia todas as operações relacionadas a agendamentos,
 * incluindo CRUD e métricas.
 */

import api from './api';

/**
 * @typedef {Object} Agendamento
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço a ser realizado
 * @property {number} valor - Valor do serviço
 * @property {string} data - Data e hora do agendamento
 * @property {string} status - Status do agendamento
 */

/**
 * @typedef {Object} Metricas
 * @property {number} total - Total de agendamentos
 * @property {number} concluidos - Total de agendamentos concluídos
 * @property {number} pendentes - Total de agendamentos pendentes
 * @property {number} valorTotal - Valor total dos agendamentos
 */

/**
 * Status possíveis para agendamentos
 * @constant {Object.<string, string>}
 */
const STATUS = {
  PENDENTE: 'pendente',
  CONCLUIDO: 'concluido',
  DELETADO: 'deletado'
};

/**
 * Busca todos os agendamentos ativos
 * @async
 * @returns {Promise<Array<Agendamento>>} Lista de agendamentos ativos
 * @throws {Error} Erro ao buscar agendamentos
 */
export const obterAgendamentos = async () => {
  try {
    console.log('📅 Buscando agendamentos...');
    const resposta = await api.get('/agendamentos');
    
    // Verifica se a resposta tem a estrutura esperada
    const agendamentos = Array.isArray(resposta.data) 
      ? resposta.data 
      : resposta.data?.agendamentos || [];

    const agendamentosAtivos = agendamentos.filter(ag => 
      ag && ag.status !== STATUS.CONCLUIDO && ag.status !== STATUS.DELETADO
    );

    console.log(`✅ ${agendamentosAtivos.length} agendamentos encontrados`);
    return agendamentosAtivos;
  } catch (erro) {
    console.error('❌ Erro ao buscar agendamentos:', erro);
    throw new Error('Não foi possível buscar os agendamentos');
  }
};

/**
 * Conclui um agendamento
 * @async
 * @param {number} id - ID do agendamento
 * @returns {Promise<Agendamento>} Agendamento concluído
 * @throws {Error} Erro ao concluir agendamento
 */
export const concluirAgendamento = async (id) => {
  try {
    console.log('✨ Concluindo agendamento:', id);
    const resposta = await api.put(`/agendamentos/${id}/concluir`);
    
    if (resposta.data?.erro) {
      throw new Error(resposta.data.erro);
    }
    
    console.log('✅ Agendamento concluído com sucesso');
    return resposta.data;
  } catch (erro) {
    console.error('❌ Erro ao concluir agendamento:', erro);
    throw new Error('Não foi possível concluir o agendamento');
  }
};

/**
 * Cria um novo agendamento
 * @async
 * @param {Agendamento} agendamento - Dados do agendamento
 * @returns {Promise<Agendamento>} Agendamento criado
 * @throws {Error} Erro ao criar agendamento
 */
export const criarAgendamento = async (agendamento) => {
  try {
    if (!agendamento.nome_cliente || !agendamento.servico || !agendamento.data) {
      throw new Error('Nome do cliente, serviço e data são obrigatórios');
    }

    const dadosAgendamento = {
      ...agendamento,
      valor: parseFloat(agendamento.valor) || 0,
      status: STATUS.PENDENTE
    };

    console.log('📝 Criando agendamento:', dadosAgendamento);
    const resposta = await api.post('/agendamentos', dadosAgendamento);
    
    // Verifica se a resposta tem o formato correto
    if (!resposta.data?.agendamento) {
      throw new Error('Formato de resposta inválido');
    }

    console.log('✅ Agendamento criado com sucesso:', resposta.data.agendamento);
    return resposta.data.agendamento;
  } catch (erro) {
    console.error('❌ Erro ao criar agendamento:', erro);
    if (erro.response?.status === 409) {
      throw new Error('Já existe um agendamento neste horário');
    }
    throw new Error(erro.response?.data?.erro || 'Não foi possível criar o agendamento');
  }
};

/**
 * Atualiza um agendamento existente
 * @async
 * @param {number} id - ID do agendamento
 * @param {Partial<Agendamento>} agendamento - Dados para atualização
 * @returns {Promise<Agendamento>} Agendamento atualizado
 * @throws {Error} Erro ao atualizar agendamento
 */
export const atualizarAgendamento = async (id, agendamento) => {
  try {
    if (!id) {
      throw new Error('ID do agendamento é obrigatório');
    }

    const dadosAtualizacao = {
      ...agendamento,
      valor: parseFloat(agendamento.valor) || 0
    };

    console.log('📝 Atualizando agendamento:', { id, dados: dadosAtualizacao });
    const resposta = await api.put(`/agendamentos/${id}`, dadosAtualizacao);
    
    console.log('✅ Agendamento atualizado com sucesso');
    return resposta.data;
  } catch (erro) {
    console.error('❌ Erro ao atualizar agendamento:', erro);
    throw new Error('Não foi possível atualizar o agendamento');
  }
};

/**
 * Remove um agendamento
 * @async
 * @param {number} id - ID do agendamento
 * @returns {Promise<Object>} Confirmação da deleção
 * @throws {Error} Erro ao deletar agendamento
 */
export const deletarAgendamento = async (id) => {
  try {
    if (!id) {
      throw new Error('ID do agendamento é obrigatório');
    }

    console.log('🗑️ Removendo agendamento:', id);
    const resposta = await api.delete(`/agendamentos/${id}`);
    
    console.log('✅ Agendamento removido com sucesso');
    return resposta.data;
  } catch (erro) {
    console.error('❌ Erro ao deletar agendamento:', erro);
    throw new Error('Não foi possível remover o agendamento');
  }
};

/**
 * Busca métricas dos agendamentos
 * @async
 * @returns {Promise<Metricas>} Métricas dos agendamentos
 * @throws {Error} Erro ao buscar métricas
 */
export const obterMetricas = async () => {
  try {
    console.log('📊 Buscando métricas...');
    const resposta = await api.get('/agendamentos/metricas');
    
    console.log('✅ Métricas obtidas com sucesso');
    return resposta.data;
  } catch (erro) {
    console.error('❌ Erro ao buscar métricas:', erro);
    throw new Error('Não foi possível buscar as métricas');
  }
};