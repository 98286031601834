/**
 * @fileoverview Página de Agendamentos do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Gerencia a visualização, criação, edição e exclusão
 * de agendamentos, com suporte a diferentes estados e animações.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

// Componentes
import ListaAgendamentos from '../componentes/ListaAgendamentos';
import FormularioAgendamento from '../componentes/formularios/FormularioAgendamento';
import DetalhesAgendamento from '../componentes/DetalhesAgendamento';

// Serviços
import { 
  obterAgendamentos, 
  criarAgendamento, 
  atualizarAgendamento, 
  deletarAgendamento,
  concluirAgendamento 
} from '../servicos/agendamentoServico';

/**
 * @typedef {Object} Agendamento
 * @property {number} id - ID do agendamento
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço agendado
 * @property {number} valor - Valor do serviço
 * @property {string} data - Data e hora do agendamento
 * @property {string} status - Status do agendamento
 */

/**
 * Status possíveis para agendamentos
 * @constant {Object.<string, string>}
 */
const STATUS = {
  PENDENTE: 'pendente',
  CONCLUIDO: 'concluido',
  CANCELADO: 'cancelado'
};

/**
 * Configurações de animação
 * @constant {Object}
 */
const ANIMACAO = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.5 }
};

/**
 * Mensagens de feedback
 * @constant {Object.<string, string>}
 */
const MENSAGENS = {
  ERRO_CARREGAR: 'Erro ao carregar agendamentos. Tente novamente.',
  ERRO_CRIAR: 'Erro ao criar agendamento. Tente novamente.',
  ERRO_ATUALIZAR: 'Erro ao atualizar agendamento. Tente novamente.',
  ERRO_DELETAR: 'Erro ao excluir agendamento. Tente novamente.',
  ERRO_CONCLUIR: 'Erro ao concluir agendamento. Tente novamente.',
  SUCESSO_CRIAR: 'Agendamento criado com sucesso!',
  SUCESSO_ATUALIZAR: 'Agendamento atualizado com sucesso!',
  SUCESSO_DELETAR: 'Agendamento excluído com sucesso!',
  SUCESSO_CONCLUIR: 'Agendamento concluído com sucesso!'
};

/**
 * Componente principal de Agendamentos
 * @returns {React.Component} Página de Agendamentos
 */
function Agendamentos() {
  // Estados
  const [agendamentos, setAgendamentos] = useState([]);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState(null);
  const [formularioAberto, setFormularioAberto] = useState(false);
  const [agendamentoEditando, setAgendamentoEditando] = useState(null);
  const [carregando, setCarregando] = useState(false);

  /**
   * Busca agendamentos ativos
   */
  const buscarAgendamentos = useCallback(async () => {
    try {
      console.log('📅 Buscando agendamentos...');
      const dados = await obterAgendamentos();
      
      const agendamentosAtivos = dados.filter(ag => 
        ag.status !== STATUS.CONCLUIDO
      );

      console.log(`✅ ${agendamentosAtivos.length} agendamentos ativos encontrados`);
      setAgendamentos(agendamentosAtivos);
    } catch (erro) {
      console.error('❌ Erro ao buscar agendamentos:', erro);
      toast.error(MENSAGENS.ERRO_CARREGAR);
    }
  }, []);

  // Carrega agendamentos ao montar
  useEffect(() => {
    buscarAgendamentos();
  }, [buscarAgendamentos]);

  /**
   * Cria novo agendamento
   * @param {Agendamento} novoAgendamento - Dados do novo agendamento
   */
  const handleAdicionarAgendamento = async (novoAgendamento) => {
    if (carregando) return;
    
    setCarregando(true);
    try {
      console.log('📝 Criando agendamento:', novoAgendamento);
      const agendamentoCriado = await criarAgendamento(novoAgendamento);
      
      // Atualiza a lista imediatamente
      setAgendamentos(prev => [...prev, agendamentoCriado]);
      setFormularioAberto(false);
      toast.success('Agendamento criado com sucesso!');
    } catch (erro) {
      console.error('❌ Erro ao criar agendamento:', erro);
      toast.error(erro.message);
    } finally {
      setCarregando(false);
    }
  };

  /**
   * Atualiza agendamento existente
   * @param {Agendamento} agendamentoAtualizado - Dados atualizados
   */
  const handleEditarAgendamento = async (agendamentoAtualizado) => {
    if (carregando || !agendamentoAtualizado.id) return;
    
    setCarregando(true);
    try {
      const dadosFormatados = {
        ...agendamentoAtualizado,
        valor: parseFloat(agendamentoAtualizado.valor) || 0
      };

      console.log('📝 Atualizando agendamento:', dadosFormatados);
      const resultado = await atualizarAgendamento(
        dadosFormatados.id, 
        dadosFormatados
      );

      setAgendamentos(prev => 
        prev.map(ag => ag.id === resultado.id ? resultado : ag)
      );
      setAgendamentoEditando(null);
      setAgendamentoSelecionado(null);
      setFormularioAberto(false);
      toast.success(MENSAGENS.SUCESSO_ATUALIZAR);
    } catch (erro) {
      console.error('❌ Erro ao atualizar agendamento:', erro);
      toast.error(MENSAGENS.ERRO_ATUALIZAR);
    } finally {
      setCarregando(false);
    }
  };

  /**
   * Conclui um agendamento
   * @param {number} id - ID do agendamento
   */
  const handleConcluirAgendamento = async (id) => {
    if (carregando) return;
    
    setCarregando(true);
    try {
      console.log('✨ Concluindo agendamento:', id);
      await concluirAgendamento(id);
      await buscarAgendamentos();
      setAgendamentoSelecionado(null);
      toast.success(MENSAGENS.SUCESSO_CONCLUIR);
    } catch (erro) {
      console.error('❌ Erro ao concluir agendamento:', erro);
      toast.error(MENSAGENS.ERRO_CONCLUIR);
    } finally {
      setCarregando(false);
    }
  };

  /**
   * Remove um agendamento
   * @param {number} id - ID do agendamento
   */
  const handleDeletarAgendamento = async (id) => {
    if (carregando) return;
    
    setCarregando(true);
    try {
      console.log('🗑️ Removendo agendamento:', id);
      await deletarAgendamento(id);
      await buscarAgendamentos();
      setAgendamentoSelecionado(null);
      toast.success(MENSAGENS.SUCESSO_DELETAR);
    } catch (erro) {
      console.error('❌ Erro ao deletar agendamento:', erro);
      toast.error(MENSAGENS.ERRO_DELETAR);
    } finally {
      setCarregando(false);
    }
  };

  /**
   * Inicia edição de agendamento
   * @param {Agendamento} agendamento - Agendamento a ser editado
   */
  const iniciarEdicao = useCallback((agendamento) => {
    console.log('📝 Iniciando edição:', agendamento);
    setAgendamentoEditando(agendamento);
    setAgendamentoSelecionado(null);
    setFormularioAberto(true);
  }, []);

  return (
    <motion.div
      {...ANIMACAO}
      className="py-6"
      role="main"
      aria-label="Gestão de Agendamentos"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">
            Agendamentos
          </h1>
          <button
            onClick={() => {
              setFormularioAberto(true);
              setAgendamentoEditando(null);
            }}
            className="px-4 py-2 bg-indigo-600 text-white rounded 
                     hover:bg-indigo-700 transition-colors duration-200
                     focus:outline-none focus:ring-2 focus:ring-indigo-500 
                     focus:ring-offset-2"
            disabled={carregando}
            aria-label="Criar novo agendamento"
          >
            {carregando ? 'Aguarde...' : 'Novo Agendamento'}
          </button>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-6">
        <ListaAgendamentos 
          agendamentos={agendamentos}
          aoSelecionarAgendamento={setAgendamentoSelecionado}
          aoConcluirAgendamento={handleConcluirAgendamento}
          carregando={carregando}
        />
      </div>

      {formularioAberto && (
        <FormularioAgendamento
          agendamento={agendamentoEditando}
          aoSalvar={agendamentoEditando ? 
            handleEditarAgendamento : 
            handleAdicionarAgendamento
          }
          aoCancelar={() => {
            setFormularioAberto(false);
            setAgendamentoEditando(null);
          }}
          carregando={carregando}
        />
      )}

      {agendamentoSelecionado && (
        <DetalhesAgendamento
          agendamento={agendamentoSelecionado}
          aoEditar={iniciarEdicao}
          aoDeletar={handleDeletarAgendamento}
          aoConcluir={handleConcluirAgendamento}
          aoFechar={() => setAgendamentoSelecionado(null)}
          carregando={carregando}
        />
      )}
    </motion.div>
  );
}

export default Agendamentos;