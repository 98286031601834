/**
 * @fileoverview Utilitários de formatação de datas do sistema BarbaBranca
 * @author Seu Nome
 * @version 1.0.0
 * 
 * @description Funções utilitárias para manipulação e formatação de datas,
 * incluindo conversões e cálculos comuns.
 */

import { format, parseISO, isValid, addMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * @typedef {Object} OpcoesFormatacao
 * @property {boolean} [incluirHora=false] - Se deve incluir horário
 * @property {boolean} [abreviado=false] - Se deve usar formato abreviado
 * @property {string} [formatoCustomizado] - Formato personalizado
 */

/**
 * Formata data para exibição
 * @param {string|Date} data - Data a ser formatada
 * @param {OpcoesFormatacao} [opcoes] - Opções de formatação
 * @returns {string} Data formatada
 */
export const formatarData = (data, opcoes = {}) => {
  try {
    if (!data) return '';

    const dataObj = data instanceof Date ? data : parseISO(data);
    
    if (!isValid(dataObj)) {
      console.error('❌ Data inválida:', data);
      return '';
    }

    // Ajusta fuso horário para local
    const dataLocal = addMinutes(dataObj, dataObj.getTimezoneOffset());

    // Define formato baseado nas opções
    let formatoData = opcoes.formatoCustomizado;
    if (!formatoData) {
      if (opcoes.abreviado) {
        formatoData = opcoes.incluirHora ? 'dd/MM/yy HH:mm' : 'dd/MM/yy';
      } else {
        formatoData = opcoes.incluirHora 
          ? "d 'de' MMMM 'às' HH:mm" 
          : "d 'de' MMMM 'de' yyyy";
      }
    }

    return format(dataLocal, formatoData, { locale: ptBR });
  } catch (erro) {
    console.error('❌ Erro ao formatar data:', erro);
    return '';
  }
};

/**
 * Formata data e hora para input type="datetime-local"
 * @param {string|Date} data - Data a ser formatada
 * @returns {string} Data formatada YYYY-MM-DDTHH:mm
 */
export const formatarDataParaInput = (data) => {
  try {
    if (!data) return '';
    
    const dataObj = data instanceof Date ? data : parseISO(data);
    
    if (!isValid(dataObj)) {
      console.error('❌ Data inválida para input:', data);
      return '';
    }

    return format(dataObj, "yyyy-MM-dd'T'HH:mm");
  } catch (erro) {
    console.error('❌ Erro ao formatar data para input:', erro);
    return '';
  }
};

/**
 * Formata apenas a data para input type="date"
 * @param {string|Date} data - Data a ser formatada
 * @returns {string} Data formatada YYYY-MM-DD
 */
export const formatarApenasData = (data) => {
  try {
    if (!data) return '';
    
    const dataObj = data instanceof Date ? data : parseISO(data);
    
    if (!isValid(dataObj)) {
      console.error('❌ Data inválida para data:', data);
      return '';
    }

    return format(dataObj, 'yyyy-MM-dd');
  } catch (erro) {
    console.error('❌ Erro ao formatar apenas data:', erro);
    return '';
  }
};

/**
 * Formata apenas a hora para input type="time"
 * @param {string|Date} data - Data a ser formatada
 * @returns {string} Hora formatada HH:mm
 */
export const formatarApenasHora = (data) => {
  try {
    if (!data) return '';
    
    const dataObj = data instanceof Date ? data : parseISO(data);
    
    if (!isValid(dataObj)) {
      console.error('❌ Data inválida para hora:', data);
      return '';
    }

    return format(dataObj, 'HH:mm');
  } catch (erro) {
    console.error('❌ Erro ao formatar apenas hora:', erro);
    return '';
  }
};

/**
 * Verifica se uma data é válida
 * @param {string|Date} data - Data a ser verificada
 * @returns {boolean} True se a data for válida
 */
export const dataEhValida = (data) => {
  try {
    if (!data) return false;
    const dataObj = data instanceof Date ? data : parseISO(data);
    return isValid(dataObj);
  } catch (erro) {
    return false;
  }
};