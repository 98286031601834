/**
 * @fileoverview Lista de Histórico de Atendimentos do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável por exibir o histórico de atendimentos
 * realizados, incluindo informações de clientes e valores.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { TrashIcon, EyeIcon } from '@heroicons/react/24/outline';

/**
 * @typedef {Object} Atendimento
 * @property {number} id - ID do atendimento
 * @property {string} data - Data e hora do atendimento
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço realizado
 * @property {number} valor - Valor do serviço
 * @property {string} status - Status do atendimento
 * @property {string} [observacoes] - Observações opcionais
 */

/**
 * @typedef {Object} PropsListaHistorico
 * @property {Array<Atendimento>} registros - Lista de atendimentos
 * @property {Function} [onDelete] - Callback para deletar registro
 * @property {Function} [onView] - Callback para visualizar detalhes
 */

/**
 * Mapeamento de cores por status
 * @constant {Object.<string, string>}
 */
const CORES_STATUS = {
  concluido: 'bg-green-100 text-green-800',
  pendente: 'bg-yellow-100 text-yellow-800',
  cancelado: 'bg-red-100 text-red-800',
  default: 'bg-gray-100 text-gray-800'
};

/**
 * Formata valor monetário
 * @param {number} valor - Valor a ser formatado
 * @returns {string} Valor formatado em BRL
 */
const formatarValor = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valor || 0);
};

/**
 * Formata data e hora
 * @param {string|Date} data - Data a ser formatada
 * @returns {string} Data formatada
 */
const formatarData = (data) => {
  try {
    return format(
      new Date(data), 
      "dd 'de' MMMM 'às' HH:mm", 
      { locale: ptBR }
    );
  } catch (erro) {
    console.error('❌ Erro ao formatar data:', erro);
    return 'Data inválida';
  }
};

/**
 * Obtém classe CSS para cor do status
 * @param {string} status - Status do atendimento
 * @returns {string} Classes CSS
 */
const obterCorStatus = (status) => {
  return CORES_STATUS[status?.toLowerCase()] || CORES_STATUS.default;
};

/**
 * Componente de listagem do histórico de atendimentos
 * @param {PropsListaHistorico} props - Propriedades do componente
 * @returns {React.Component} Componente ListaHistorico
 */
function ListaHistorico({ registros = [], onDelete, onView }) {
  return (
    <div 
      className="bg-white shadow rounded-lg overflow-hidden"
      role="region" 
      aria-label="Histórico de atendimentos"
    >
      {registros.length === 0 ? (
        <div className="p-8 text-center">
          <p className="text-gray-500">
            Nenhum registro encontrado neste período.
          </p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data/Hora
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Cliente
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Serviço
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valor
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {registros.map((registro) => (
                <tr 
                  key={registro.id} 
                  className="hover:bg-gray-50 transition-colors duration-150"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatarData(registro.data)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">
                        {registro.nome_cliente}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {registro.servico}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatarValor(registro.valor)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span 
                      className={`
                        px-2 py-1 inline-flex text-xs leading-5 
                        font-semibold rounded-full
                        ${obterCorStatus(registro.status)}
                      `}
                      role="status"
                    >
                      {registro.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end space-x-2">
                      {onView && (
                        <button
                          onClick={() => onView(registro)}
                          className="text-indigo-600 hover:text-indigo-900 
                                   transition-colors duration-150"
                          title="Ver detalhes do atendimento"
                          aria-label={`Ver detalhes do atendimento de ${registro.nome_cliente}`}
                        >
                          <EyeIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      )}
                      {onDelete && (
                        <button
                          onClick={() => {
                            if (window.confirm('Tem certeza que deseja excluir este registro?')) {
                              onDelete(registro.id);
                            }
                          }}
                          className="text-red-600 hover:text-red-900 
                                   transition-colors duration-150"
                          title="Excluir registro"
                          aria-label={`Excluir registro de atendimento de ${registro.nome_cliente}`}
                        >
                          <TrashIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* Rodapé com totais */}
            <tfoot className="bg-gray-50">
              <tr>
                <td colSpan="3" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  Total do período:
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {formatarValor(
                    registros.reduce((total, reg) => total + (reg.valor || 0), 0)
                  )}
                </td>
                <td colSpan="2"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
}

// Verificação de tipos em desenvolvimento
if (process.env.NODE_ENV === 'development') {
  ListaHistorico.propTypes = {
    registros: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        data: PropTypes.string.isRequired,
        nome_cliente: PropTypes.string.isRequired,
        servico: PropTypes.string.isRequired,
        valor: PropTypes.number,
        status: PropTypes.string.isRequired
      })
    ),
    onDelete: PropTypes.func,
    onView: PropTypes.func
  };
}

export default ListaHistorico;