/**
 * @fileoverview Utilitários de formatação de texto e cálculos do BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Funções utilitárias para formatação de textos e cálculos comuns
 * utilizados em toda a aplicação.
 */

/**
 * Formata texto para título
 * @param {string} texto - Texto a ser formatado
 * @returns {string} Texto formatado como título
 */
export const formatarTitulo = (texto) => {
  if (!texto) return '';
  
  return texto
    .toLowerCase()
    .split(' ')
    .map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1))
    .join(' ');
};

/**
 * Formata valor monetário
 * @param {number} valor - Valor a ser formatado
 * @param {boolean} [simbolo=true] - Se deve incluir símbolo da moeda
 * @returns {string} Valor formatado em BRL
 */
export const formatarMoeda = (valor, simbolo = true) => {
  try {
    const valorNumerico = Number(valor);
    if (isNaN(valorNumerico)) return 'R$ 0,00';

    return new Intl.NumberFormat('pt-BR', {
      style: simbolo ? 'currency' : 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(valorNumerico);
  } catch (erro) {
    console.error('❌ Erro ao formatar moeda:', erro);
    return 'R$ 0,00';
  }
};

/**
 * Calcula porcentagem com limite máximo de 100%
 * @param {number} valor - Valor parcial
 * @param {number} total - Valor total
 * @returns {number} Porcentagem calculada
 */
export const calcularPorcentagem = (valor, total) => {
  try {
    if (!valor || !total) return 0;
    if (typeof valor !== 'number' || typeof total !== 'number') {
      throw new Error('Valores devem ser numéricos');
    }
    
    const porcentagem = (valor / total) * 100;
    return Math.min(Math.round(porcentagem), 100);
  } catch (erro) {
    console.error('❌ Erro ao calcular porcentagem:', erro);
    return 0;
  }
};

/**
 * Formata número com separador de milhares
 * @param {number} numero - Número a ser formatado
 * @returns {string} Número formatado
 */
export const formatarNumero = (numero) => {
  try {
    if (typeof numero !== 'number') return '0';
    
    return new Intl.NumberFormat('pt-BR').format(numero);
  } catch (erro) {
    console.error('❌ Erro ao formatar número:', erro);
    return '0';
  }
};

/**
 * Formata texto como CPF
 * @param {string} cpf - CPF a ser formatado
 * @returns {string} CPF formatado
 */
export const formatarCPF = (cpf) => {
  try {
    if (!cpf) return '';
    const numeros = cpf.replace(/\D/g, '');
    return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } catch (erro) {
    console.error('❌ Erro ao formatar CPF:', erro);
    return '';
  }
};

/**
 * Formata texto como telefone
 * @param {string} telefone - Telefone a ser formatado
 * @returns {string} Telefone formatado
 */
export const formatarTelefone = (telefone) => {
  try {
    if (!telefone) return '';
    const numeros = telefone.replace(/\D/g, '');
    return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } catch (erro) {
    console.error('❌ Erro ao formatar telefone:', erro);
    return '';
  }
};