/**
 * @fileoverview Formulário de Funcionários do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável pelo cadastro e edição de funcionários,
 * incluindo validações e formatação dos dados.
 */

import React, { useEffect, forwardRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactInputMask from 'react-input-mask';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

/**
 * @typedef {Object} Funcionario
 * @property {string} nome - Nome completo
 * @property {string} cpf - CPF formatado
 * @property {string} email - Email
 * @property {string} telefone - Telefone formatado
 * @property {string} data_nascimento - Data de nascimento
 * @property {string} cargo - Cargo do funcionário
 * @property {string[]} especialidades - Lista de especialidades
 * @property {string} status - Status (ativo/inativo)
 */

/**
 * Input mascarado com forward ref
 * @type {React.ForwardRefExoticComponent}
 */
const InputMascarado = forwardRef((props, ref) => (
  <ReactInputMask {...props} inputRef={ref}>
    {(inputProps) => (
      <input
        {...inputProps}
        className={`
          mt-1 block w-full rounded-md shadow-sm 
          ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}
          ${props.isError ? 'border-red-500' : 'border-gray-300'}
          focus:border-indigo-300 focus:ring focus:ring-indigo-200 
          focus:ring-opacity-50
        `}
        aria-invalid={props.isError ? 'true' : 'false'}
      />
    )}
  </ReactInputMask>
));

InputMascarado.displayName = 'InputMascarado';

/**
 * Lista de especialidades disponíveis
 * @constant {Array<{value: string, label: string}>}
 */
const ESPECIALIDADES = [
  { value: 'corte_cabelo', label: 'Corte de Cabelo' },
  { value: 'corte_navalhado', label: 'Corte Navalhado' },
  { value: 'sobrancelha', label: 'Designer de Sobrancelha' },
  { value: 'platinado', label: 'Platinado' },
  { value: 'luzes', label: 'Luzes' }
];

/**
 * Lista de cargos disponíveis
 * @constant {Array<{value: string, label: string}>}
 */
const CARGOS = [
  { value: 'barbeiro', label: 'Barbeiro' },
  { value: 'barbeiro_master', label: 'Barbeiro Master' },
  { value: 'barbeiro_senior', label: 'Barbeiro Sênior' },
  { value: 'aprendiz', label: 'Aprendiz' },
  { value: 'gerente', label: 'Gerente' }
];

/**
 * Validador de CPF
 * @param {string} cpf - CPF a ser validado
 * @returns {boolean} True se CPF é válido
 */
const validarCPF = (cpf) => {
  const numeros = cpf.replace(/[^\d]/g, '');
  if (numeros.length !== 11) return false;
  
  // Validação básica de CPF
  if (/^(\d)\1+$/.test(numeros)) return false;
  
  return true;
};

/**
 * Esquema de validação do formulário
 */
const esquemaValidacao = yup.object({
  nome: yup.string()
    .required('Nome é obrigatório')
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(100, 'Nome muito longo'),
  
  cpf: yup.string()
    .required('CPF é obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('cpf', 'CPF inválido', validarCPF),
  
  email: yup.string()
    .required('Email é obrigatório')
    .email('Email inválido')
    .max(100, 'Email muito longo'),
  
  telefone: yup.string()
    .required('Telefone é obrigatório')
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'Telefone inválido'),
  
  dataNascimento: yup.string()
    .required('Data de nascimento é obrigatória')
    .test('idade-minima', 'Funcionário deve ter pelo menos 18 anos', value => {
      if (!value) return false;
      const data = new Date(value);
      const hoje = new Date();
      const idade = hoje.getFullYear() - data.getFullYear();
      return idade >= 18;
    }),
  
  cargo: yup.object()
    .required('Cargo é obrigatório')
    .nullable()
    .test('cargo-valido', 'Cargo inválido', value => {
      return CARGOS.some(c => c.value === value?.value);
    }),
  
  especialidades: yup.array()
    .min(1, 'Selecione pelo menos uma especialidade')
    .required('Especialidades são obrigatórias')
    .test('especialidades-validas', 'Especialidades inválidas', value => {
      return value?.every(e => ESPECIALIDADES.some(esp => esp.value === e.value));
    }),
  
  status: yup.string()
    .required('Status é obrigatório')
    .oneOf(['ativo', 'inativo'], 'Status inválido')
});

/**
 * Componente de formulário de funcionário
 * @param {Object} props - Propriedades do componente
 * @param {Funcionario} [props.funcionarioParaEditar] - Dados do funcionário em edição
 * @param {Function} props.onSalvar - Callback após salvar
 * @param {Function} props.onCancelar - Callback para cancelar
 */
function FormularioFuncionario({ funcionarioParaEditar, onSalvar, onCancelar }) {
  const [enviando, setEnviando] = useState(false);

  console.log('📝 Renderizando formulário:', {
    editando: !!funcionarioParaEditar,
    dados: funcionarioParaEditar
  });

  const { 
    control, 
    handleSubmit, 
    formState: { errors },
    reset 
  } = useForm({
    resolver: yupResolver(esquemaValidacao),
    defaultValues: {
      nome: '',
      cpf: '',
      email: '',
      telefone: '',
      dataNascimento: '',
      cargo: null,
      especialidades: [],
      status: 'ativo'
    }
  });

  // Carrega dados do funcionário para edição
  useEffect(() => {
    if (funcionarioParaEditar) {
      console.log('🔄 Carregando dados para edição:', funcionarioParaEditar);

      const valores = {
        nome: funcionarioParaEditar.nome || '',
        cpf: funcionarioParaEditar.cpf || '',
        email: funcionarioParaEditar.email || '',
        telefone: funcionarioParaEditar.telefone || '',
        dataNascimento: funcionarioParaEditar.data_nascimento || '',
        cargo: CARGOS.find(c => c.value === funcionarioParaEditar.cargo) || null,
        especialidades: funcionarioParaEditar.especialidades ? 
          ESPECIALIDADES.filter(e => {
            const especialidadesArray = typeof funcionarioParaEditar.especialidades === 'string' 
              ? JSON.parse(funcionarioParaEditar.especialidades) 
              : funcionarioParaEditar.especialidades;
            return especialidadesArray.includes(e.value);
          }) : [],
        status: funcionarioParaEditar.status || 'ativo'
      };

      reset(valores);
    }
  }, [funcionarioParaEditar, reset]);

  /**
   * Processa o envio do formulário
   * @param {Object} dados - Dados do formulário
   */
  const onSubmit = async (dados) => {
    try {
      setEnviando(true);
      console.log('📤 Iniciando envio:', dados);
      
      const dadosFormatados = {
        ...dados,
        cargo: dados.cargo.value,
        especialidades: dados.especialidades.map(e => e.value),
        data_nascimento: dados.dataNascimento,
        status: dados.status
      };
      
      await onSalvar(dadosFormatados);
      toast.success(
        funcionarioParaEditar 
          ? 'Funcionário atualizado com sucesso!' 
          : 'Funcionário cadastrado com sucesso!'
      );
    } catch (erro) {
      console.error('❌ Erro ao salvar funcionário:', erro);
      
      if (erro.response?.status === 409) {
        toast.error('CPF ou email já cadastrado');
      } else if (erro.response?.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        window.location.href = '/login';
      } else {
        toast.error('Erro ao salvar funcionário. Tente novamente.');
      }
    } finally {
      setEnviando(false);
    }
  };

  return (
    <div className="bg-white p-6" role="dialog" aria-labelledby="titulo-formulario">
      {/* Cabeçalho */}
      <div className="flex justify-between items-center mb-6">
        <h2 id="titulo-formulario" className="text-2xl font-bold text-gray-800">
          {funcionarioParaEditar ? 'Editar Funcionário' : 'Novo Funcionário'}
        </h2>
        <button 
          onClick={onCancelar}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
          aria-label="Fechar formulário"
        >
          <FaTimes size={24} />
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {/* Nome */}
        <div>
          <label htmlFor="nome" className="block text-sm font-medium text-gray-700">
            Nome
          </label>
          <Controller
            name="nome"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                id="nome"
                type="text"
                disabled={enviando}
                placeholder="Nome completo"
                aria-invalid={!!errors.nome}
                className={`
                  mt-1 block w-full rounded-md shadow-sm
                  ${enviando ? 'opacity-50 cursor-not-allowed' : ''}
                  ${errors.nome ? 'border-red-500' : 'border-gray-300'}
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                  focus:ring-opacity-50
                `}
              />
            )}
          />
          {errors.nome && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.nome.message}
            </p>
          )}
        </div>

        {/* CPF */}
        <div>
          <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">
            CPF
          </label>
          <Controller
            name="cpf"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputMascarado
                {...field}
                id="cpf"
                mask="999.999.999-99"
                disabled={enviando}
                placeholder="000.000.000-00"
                isError={!!errors.cpf}
              />
            )}
          />
          {errors.cpf && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.cpf.message}
            </p>
          )}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                id="email"
                type="email"
                disabled={enviando}
                placeholder="exemplo@email.com"
                aria-invalid={!!errors.email}
                className={`
                  mt-1 block w-full rounded-md shadow-sm
                  ${enviando ? 'opacity-50 cursor-not-allowed' : ''}
                  ${errors.email ? 'border-red-500' : 'border-gray-300'}
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                  focus:ring-opacity-50
                `}
              />
            )}
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.email.message}
            </p>
          )}
        </div>

        {/* Data de Nascimento */}
        <div>
          <label htmlFor="dataNascimento" className="block text-sm font-medium text-gray-700">
            Data de Nascimento
          </label>
          <Controller
            name="dataNascimento"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <input
                {...field}
                id="dataNascimento"
                type="date"
                value={value || ''}
                onChange={(e) => onChange(e.target.value)}
                disabled={enviando}
                max={new Date().toISOString().split('T')[0]}
                className={`
                  mt-1 block w-full rounded-md shadow-sm
                  ${enviando ? 'opacity-50 cursor-not-allowed' : ''}
                  ${errors.dataNascimento ? 'border-red-500' : 'border-gray-300'}
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                  focus:ring-opacity-50
                `}
              />
            )}
          />
          {errors.dataNascimento && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.dataNascimento.message}
            </p>
          )}
        </div>

        {/* Telefone */}
        <div>
          <label htmlFor="telefone" className="block text-sm font-medium text-gray-700">
            Telefone
          </label>
          <Controller
            name="telefone"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputMascarado
                {...field}
                id="telefone"
                mask="(99) 99999-9999"
                disabled={enviando}
                placeholder="(00) 00000-0000"
                isError={!!errors.telefone}
              />
            )}
          />
          {errors.telefone && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.telefone.message}
            </p>
          )}
        </div>

        {/* Cargo */}
        <div>
          <label htmlFor="cargo" className="block text-sm font-medium text-gray-700">
            Cargo
          </label>
          <Controller
            name="cargo"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="cargo"
                options={CARGOS}
                isDisabled={enviando}
                placeholder="Selecione um cargo"
                noOptionsMessage={() => "Nenhum cargo encontrado"}
                classNamePrefix="select"
                className={errors.cargo ? 'border-red-500' : ''}
                aria-invalid={!!errors.cargo}
              />
            )}
          />
          {errors.cargo && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.cargo.message}
            </p>
          )}
        </div>

        {/* Especialidades */}
        <div>
          <label htmlFor="especialidades" className="block text-sm font-medium text-gray-700">
            Especialidades
          </label>
          <Controller
            name="especialidades"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="especialidades"
                options={ESPECIALIDADES}
                isMulti
                isDisabled={enviando}
                placeholder="Selecione as especialidades"
                noOptionsMessage={() => "Nenhuma especialidade encontrada"}
                classNamePrefix="select"
                className={errors.especialidades ? 'border-red-500' : ''}
                aria-invalid={!!errors.especialidades}
              />
            )}
          />
          {errors.especialidades && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.especialidades.message}
            </p>
          )}
        </div>

        {/* Status */}
        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <Controller
            name="status"
            control={control}
            defaultValue="ativo"
            render={({ field }) => (
              <select
                {...field}
                id="status"
                disabled={enviando}
                aria-invalid={!!errors.status}
                className={`
                  mt-1 block w-full rounded-md shadow-sm
                  ${enviando ? 'opacity-50 cursor-not-allowed' : ''}
                  ${errors.status ? 'border-red-500' : 'border-gray-300'}
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 
                  focus:ring-opacity-50
                `}
              >
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </select>
            )}
          />
          {errors.status && (
            <p className="mt-1 text-sm text-red-500" role="alert">
              {errors.status.message}
            </p>
          )}
        </div>

        {/* Botões */}
        <div className="flex justify-end space-x-4 pt-6">
          <button
            type="button"
            onClick={onCancelar}
            disabled={enviando}
            className={`
              px-4 py-2 border rounded text-gray-600 
              ${enviando ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}
              transition-colors duration-200
            `}
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={enviando}
            className={`
              px-4 py-2 bg-blue-500 text-white rounded 
              ${enviando ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}
              transition-colors duration-200
            `}
          >
            {enviando 
              ? 'Salvando...' 
              : funcionarioParaEditar 
                ? 'Atualizar Funcionário' 
                : 'Cadastrar Funcionário'
            }
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormularioFuncionario;

