/**
 * @fileoverview Formulário de Agendamentos do sistema BarbaBranca
 * @author Arthur, Felipe, Mateus, João Pedro
 * @version 1.0.0
 * 
 * @description Componente responsável pelo cadastro e edição de agendamentos,
 * incluindo seleção de serviços e definição de horários.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * @typedef {Object} Servico
 * @property {number} id - ID do serviço
 * @property {string} nome - Nome do serviço
 * @property {number} valor - Valor do serviço
 */

/**
 * @typedef {Object} Agendamento
 * @property {number} [id] - ID do agendamento (opcional para novos)
 * @property {string} nome_cliente - Nome do cliente
 * @property {string} servico - Serviço selecionado
 * @property {number} valor - Valor do serviço
 * @property {string} data - Data e hora do agendamento
 * @property {string} status - Status do agendamento
 */

/**
 * @typedef {Object} PropsFormulario
 * @property {Agendamento} [agendamento] - Agendamento para edição
 * @property {Function} aoSalvar - Callback ao salvar
 * @property {Function} aoCancelar - Callback ao cancelar
 * @property {boolean} carregando - Estado de loading
 */

/**
 * Lista de serviços disponíveis
 * @constant {Array<Servico>}
 */
const SERVICOS_DISPONIVEIS = [
  { id: 1, nome: 'Corte de Cabelo', valor: 25.00 },
  { id: 2, nome: 'Corte navalhado', valor: 30.00 },
  { id: 3, nome: 'Sobrancelha', valor: 10.00 },
  { id: 4, nome: 'Platinado', valor: 180.00 },
  { id: 5, nome: 'Luzes', valor: 150.00 }
];

/**
 * Configurações de animação do modal
 * @constant {Object}
 */
const ANIMACAO_MODAL = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 50 },
  transition: { duration: 0.3 }
};

/**
 * Estado inicial do formulário
 * @constant {Object}
 */
const ESTADO_INICIAL = {
  nomeCliente: '',
  servico: '',
  valor: '',
  data: '',
  hora: ''
};

/**
 * Formata número como moeda
 * @param {number} valor - Valor a formatar
 * @returns {string} Valor formatado
 */
const formatarMoeda = (valor) => {
  return valor.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
};

/**
 * Obtém data atual formatada
 * @returns {string} Data atual no formato YYYY-MM-DD
 */
const obterDataAtual = () => {
  return new Date().toISOString().split('T')[0];
};

/**
 * Componente de formulário de agendamento
 * @param {PropsFormulario} props - Propriedades do componente
 * @returns {React.Component} Componente FormularioAgendamento
 */
function FormularioAgendamento({ 
  agendamento, 
  aoSalvar, 
  aoCancelar, 
  carregando = false 
}) {
  // Estado do formulário
  const [dados, setDados] = useState(ESTADO_INICIAL);
  const [erro, setErro] = useState(null);

  /**
   * Carrega dados do agendamento para edição
   */
  useEffect(() => {
    if (agendamento) {
      try {
        console.log('📝 Carregando dados para edição:', agendamento);
        const dataAgendamento = parseISO(agendamento.data);
        
        setDados({
          nomeCliente: agendamento.nome_cliente || '',
          servico: agendamento.servico || '',
          valor: agendamento.valor?.toString() || '',
          data: format(dataAgendamento, 'yyyy-MM-dd'),
          hora: format(dataAgendamento, 'HH:mm'),
        });
      } catch (erro) {
        console.error('❌ Erro ao carregar dados:', erro);
        setErro('Erro ao carregar dados do agendamento');
      }
    }
  }, [agendamento]);

  /**
   * Manipula mudanças nos campos
   * @param {React.ChangeEvent<HTMLInputElement|HTMLSelectElement>} evento 
   */
  const handleMudanca = (evento) => {
    const { name, value } = evento.target;
    
    if (name === 'servico') {
      const servicoSelecionado = SERVICOS_DISPONIVEIS.find(s => s.nome === value);
      setDados(prev => ({
        ...prev,
        servico: value,
        valor: servicoSelecionado ? servicoSelecionado.valor.toFixed(2) : ''
      }));
    } else {
      setDados(prev => ({ ...prev, [name]: value }));
    }
  };

  /**
   * Valida dados antes do envio
   * @returns {boolean} True se dados são válidos
   */
  const validarDados = () => {
    const dataAgendamento = new Date(`${dados.data}T${dados.hora}`);
    const agora = new Date();

    if (dataAgendamento < agora) {
      setErro('A data do agendamento não pode ser no passado');
      return false;
    }

    return true;
  };

  /**
   * Processa envio do formulário
   * @param {React.FormEvent} evento 
   */
  const handleSubmit = (evento) => {
    evento.preventDefault();
    setErro(null);

    if (!validarDados()) return;

    try {
      console.log('📤 Enviando dados:', dados);
      
      aoSalvar({
        id: agendamento?.id,
        nome_cliente: dados.nomeCliente,
        servico: dados.servico,
        valor: parseFloat(dados.valor),
        data: `${dados.data}T${dados.hora}`,
        status: agendamento?.status || 'pendente'
      });
    } catch (erro) {
      console.error('❌ Erro ao salvar agendamento:', erro);
      setErro('Erro ao salvar agendamento. Tente novamente.');
    }
  };

  return (
    <motion.div
      {...ANIMACAO_MODAL}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="titulo-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 
          id="titulo-modal"
          className="text-lg font-medium leading-6 text-gray-900 mb-4"
        >
          {agendamento ? 'Editar Agendamento' : 'Novo Agendamento'}
        </h3>

        {erro && (
          <div 
            className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded"
            role="alert"
          >
            {erro}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Nome do Cliente */}
          <div>
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="nomeCliente"
            >
              Nome do Cliente
            </label>
            <input
              type="text"
              name="nomeCliente"
              id="nomeCliente"
              value={dados.nomeCliente}
              onChange={handleMudanca}
              disabled={carregando}
              className={`
                shadow appearance-none border rounded w-full py-2 px-3 
                text-gray-700 leading-tight focus:outline-none 
                focus:ring-2 focus:ring-blue-500
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
              required
              aria-required="true"
              placeholder="Nome do cliente"
            />
          </div>

          {/* Serviço */}
          <div>
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="servico"
            >
              Serviço
            </label>
            <select
              name="servico"
              id="servico"
              value={dados.servico}
              onChange={handleMudanca}
              disabled={carregando}
              className={`
                shadow appearance-none border rounded w-full py-2 px-3 
                text-gray-700 leading-tight focus:outline-none 
                focus:ring-2 focus:ring-blue-500
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
              required
              aria-required="true"
            >
              <option value="">Selecione um serviço</option>
              {SERVICOS_DISPONIVEIS.map(servico => (
                <option key={servico.id} value={servico.nome}>
                  {servico.nome} - {formatarMoeda(servico.valor)}
                </option>
              ))}
            </select>
          </div>

          {/* Valor */}
          <div>
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="valor"
            >
              Valor (R$)
            </label>
            <input
              type="number"
              step="0.01"
              min="0"
              name="valor"
              id="valor"
              value={dados.valor}
              onChange={handleMudanca}
              disabled={carregando}
              className={`
                shadow appearance-none border rounded w-full py-2 px-3 
                text-gray-700 leading-tight focus:outline-none 
                focus:ring-2 focus:ring-blue-500
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
              required
              aria-required="true"
            />
          </div>

          {/* Data */}
          <div>
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="data"
            >
              Data
            </label>
            <input
              type="date"
              name="data"
              id="data"
              value={dados.data}
              onChange={handleMudanca}
              disabled={carregando}
              min={obterDataAtual()}
              className={`
                shadow appearance-none border rounded w-full py-2 px-3 
                text-gray-700 leading-tight focus:outline-none 
                focus:ring-2 focus:ring-blue-500
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
              required
              aria-required="true"
            />
          </div>

          {/* Hora */}
          <div>
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="hora"
            >
              Hora
            </label>
            <input
              type="time"
              name="hora"
              id="hora"
              value={dados.hora}
              onChange={handleMudanca}
              disabled={carregando}
              className={`
                shadow appearance-none border rounded w-full py-2 px-3 
                text-gray-700 leading-tight focus:outline-none 
                focus:ring-2 focus:ring-blue-500
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
              required
              aria-required="true"
            />
          </div>

          {/* Botões */}
          <div className="flex justify-between pt-4">
            <button
              type="submit"
              disabled={carregando}
              className={`
                bg-blue-500 hover:bg-blue-700 text-white font-bold 
                py-2 px-4 rounded focus:outline-none focus:ring-2 
                focus:ring-blue-500 focus:ring-offset-2
                transition-colors duration-200
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              {carregando ? 'Salvando...' : 'Salvar'}
            </button>
            <button
              type="button"
              onClick={aoCancelar}
              disabled={carregando}
              className={`
                bg-red-500 hover:bg-red-700 text-white font-bold 
                py-2 px-4 rounded focus:outline-none focus:ring-2 
                focus:ring-red-500 focus:ring-offset-2
                transition-colors duration-200
                ${carregando ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
}

// Verificação de tipos em desenvolvimento
if (process.env.NODE_ENV === 'development') {
  FormularioAgendamento.propTypes = {
    agendamento: PropTypes.shape({
      id: PropTypes.number,
      nome_cliente: PropTypes.string,
      servico: PropTypes.string,
      valor: PropTypes.number,
      data: PropTypes.string,
      status: PropTypes.string
    }),
    aoSalvar: PropTypes.func.isRequired,
    aoCancelar: PropTypes.func.isRequired,
    carregando: PropTypes.bool
  };
}

export default FormularioAgendamento;